// source: pbtypes/gen/calibration/auto_focus_data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_calibration_auto_focus_data_point_pb = require('../../../pbtypes/gen/calibration/auto_focus_data_point_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_auto_focus_data_point_pb);
goog.exportSymbol('proto.calibration.AutoFocusData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.calibration.AutoFocusData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.calibration.AutoFocusData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.calibration.AutoFocusData.displayName = 'proto.calibration.AutoFocusData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.calibration.AutoFocusData.prototype.toObject = function(opt_includeInstance) {
  return proto.calibration.AutoFocusData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.calibration.AutoFocusData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.calibration.AutoFocusData.toObject = function(includeInstance, msg) {
  var f, obj = {
isValid: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
calibratedAfPos: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
calibratedTemp: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
closeFocusDataPoint: (f = msg.getCloseFocusDataPoint()) && pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint.toObject(includeInstance, f),
farFocusDataPoint: (f = msg.getFarFocusDataPoint()) && pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.calibration.AutoFocusData}
 */
proto.calibration.AutoFocusData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.calibration.AutoFocusData;
  return proto.calibration.AutoFocusData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.calibration.AutoFocusData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.calibration.AutoFocusData}
 */
proto.calibration.AutoFocusData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsValid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCalibratedAfPos(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCalibratedTemp(value);
      break;
    case 4:
      var value = new pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint;
      reader.readMessage(value,pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint.deserializeBinaryFromReader);
      msg.setCloseFocusDataPoint(value);
      break;
    case 5:
      var value = new pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint;
      reader.readMessage(value,pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint.deserializeBinaryFromReader);
      msg.setFarFocusDataPoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.calibration.AutoFocusData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.calibration.AutoFocusData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.calibration.AutoFocusData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.calibration.AutoFocusData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsValid();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getCalibratedAfPos();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getCalibratedTemp();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCloseFocusDataPoint();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint.serializeBinaryToWriter
    );
  }
  f = message.getFarFocusDataPoint();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_valid = 1;
 * @return {boolean}
 */
proto.calibration.AutoFocusData.prototype.getIsValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.calibration.AutoFocusData} returns this
 */
proto.calibration.AutoFocusData.prototype.setIsValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double calibrated_af_pos = 2;
 * @return {number}
 */
proto.calibration.AutoFocusData.prototype.getCalibratedAfPos = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.calibration.AutoFocusData} returns this
 */
proto.calibration.AutoFocusData.prototype.setCalibratedAfPos = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double calibrated_temp = 3;
 * @return {number}
 */
proto.calibration.AutoFocusData.prototype.getCalibratedTemp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.calibration.AutoFocusData} returns this
 */
proto.calibration.AutoFocusData.prototype.setCalibratedTemp = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional AutoFocusDataPoint close_focus_data_point = 4;
 * @return {?proto.calibration.AutoFocusDataPoint}
 */
proto.calibration.AutoFocusData.prototype.getCloseFocusDataPoint = function() {
  return /** @type{?proto.calibration.AutoFocusDataPoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint, 4));
};


/**
 * @param {?proto.calibration.AutoFocusDataPoint|undefined} value
 * @return {!proto.calibration.AutoFocusData} returns this
*/
proto.calibration.AutoFocusData.prototype.setCloseFocusDataPoint = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.AutoFocusData} returns this
 */
proto.calibration.AutoFocusData.prototype.clearCloseFocusDataPoint = function() {
  return this.setCloseFocusDataPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.AutoFocusData.prototype.hasCloseFocusDataPoint = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AutoFocusDataPoint far_focus_data_point = 5;
 * @return {?proto.calibration.AutoFocusDataPoint}
 */
proto.calibration.AutoFocusData.prototype.getFarFocusDataPoint = function() {
  return /** @type{?proto.calibration.AutoFocusDataPoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_auto_focus_data_point_pb.AutoFocusDataPoint, 5));
};


/**
 * @param {?proto.calibration.AutoFocusDataPoint|undefined} value
 * @return {!proto.calibration.AutoFocusData} returns this
*/
proto.calibration.AutoFocusData.prototype.setFarFocusDataPoint = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.AutoFocusData} returns this
 */
proto.calibration.AutoFocusData.prototype.clearFarFocusDataPoint = function() {
  return this.setFarFocusDataPoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.AutoFocusData.prototype.hasFarFocusDataPoint = function() {
  return jspb.Message.getField(this, 5) != null;
};


goog.object.extend(exports, proto.calibration);
