// source: pbtypes/tools/cloud_api/livestream_settings.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.cloud_api.LivestreamEgress', null, global);
goog.exportSymbol('proto.cloud_api.LivestreamEgressCredentialsRequest', null, global);
goog.exportSymbol('proto.cloud_api.LivestreamEgressCredentialsResponse', null, global);
goog.exportSymbol('proto.cloud_api.LivestreamEgressSettings', null, global);
goog.exportSymbol('proto.cloud_api.LivestreamEgressValidateRequest', null, global);
goog.exportSymbol('proto.cloud_api.LivestreamEgressValidateResponse', null, global);
goog.exportSymbol('proto.cloud_api.LivestreamSettings', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamSettings.displayName = 'proto.cloud_api.LivestreamSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamEgressSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamEgressSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamEgressSettings.displayName = 'proto.cloud_api.LivestreamEgressSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamEgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamEgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamEgress.displayName = 'proto.cloud_api.LivestreamEgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamEgressCredentialsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamEgressCredentialsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamEgressCredentialsRequest.displayName = 'proto.cloud_api.LivestreamEgressCredentialsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamEgressCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamEgressCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamEgressCredentialsResponse.displayName = 'proto.cloud_api.LivestreamEgressCredentialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamEgressValidateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamEgressValidateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamEgressValidateRequest.displayName = 'proto.cloud_api.LivestreamEgressValidateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.LivestreamEgressValidateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.LivestreamEgressValidateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.LivestreamEgressValidateResponse.displayName = 'proto.cloud_api.LivestreamEgressValidateResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
egressSettings: (f = msg.getEgressSettings()) && proto.cloud_api.LivestreamEgressSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamSettings}
 */
proto.cloud_api.LivestreamSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamSettings;
  return proto.cloud_api.LivestreamSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamSettings}
 */
proto.cloud_api.LivestreamSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.LivestreamEgressSettings;
      reader.readMessage(value,proto.cloud_api.LivestreamEgressSettings.deserializeBinaryFromReader);
      msg.setEgressSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEgressSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.LivestreamEgressSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional LivestreamEgressSettings egress_settings = 1;
 * @return {?proto.cloud_api.LivestreamEgressSettings}
 */
proto.cloud_api.LivestreamSettings.prototype.getEgressSettings = function() {
  return /** @type{?proto.cloud_api.LivestreamEgressSettings} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.LivestreamEgressSettings, 1));
};


/**
 * @param {?proto.cloud_api.LivestreamEgressSettings|undefined} value
 * @return {!proto.cloud_api.LivestreamSettings} returns this
*/
proto.cloud_api.LivestreamSettings.prototype.setEgressSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.LivestreamSettings} returns this
 */
proto.cloud_api.LivestreamSettings.prototype.clearEgressSettings = function() {
  return this.setEgressSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.LivestreamSettings.prototype.hasEgressSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamEgressSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamEgressSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
enabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
externalHost: jspb.Message.getFieldWithDefault(msg, 2, ""),
externalToken: jspb.Message.getFieldWithDefault(msg, 3, ""),
egress: (f = msg.getEgress()) && proto.cloud_api.LivestreamEgress.toObject(includeInstance, f),
rtspEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
dataEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamEgressSettings}
 */
proto.cloud_api.LivestreamEgressSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamEgressSettings;
  return proto.cloud_api.LivestreamEgressSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamEgressSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamEgressSettings}
 */
proto.cloud_api.LivestreamEgressSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalHost(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalToken(value);
      break;
    case 4:
      var value = new proto.cloud_api.LivestreamEgress;
      reader.readMessage(value,proto.cloud_api.LivestreamEgress.deserializeBinaryFromReader);
      msg.setEgress(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRtspEnabled(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDataEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamEgressSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamEgressSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getExternalHost();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEgress();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.cloud_api.LivestreamEgress.serializeBinaryToWriter
    );
  }
  f = message.getRtspEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDataEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
 */
proto.cloud_api.LivestreamEgressSettings.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string external_host = 2;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.getExternalHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
 */
proto.cloud_api.LivestreamEgressSettings.prototype.setExternalHost = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string external_token = 3;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.getExternalToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
 */
proto.cloud_api.LivestreamEgressSettings.prototype.setExternalToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional LivestreamEgress egress = 4;
 * @return {?proto.cloud_api.LivestreamEgress}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.getEgress = function() {
  return /** @type{?proto.cloud_api.LivestreamEgress} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.LivestreamEgress, 4));
};


/**
 * @param {?proto.cloud_api.LivestreamEgress|undefined} value
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
*/
proto.cloud_api.LivestreamEgressSettings.prototype.setEgress = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
 */
proto.cloud_api.LivestreamEgressSettings.prototype.clearEgress = function() {
  return this.setEgress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.hasEgress = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool rtsp_enabled = 5;
 * @return {boolean}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.getRtspEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
 */
proto.cloud_api.LivestreamEgressSettings.prototype.setRtspEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool data_enabled = 6;
 * @return {boolean}
 */
proto.cloud_api.LivestreamEgressSettings.prototype.getDataEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.LivestreamEgressSettings} returns this
 */
proto.cloud_api.LivestreamEgressSettings.prototype.setDataEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamEgress.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamEgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamEgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgress.toObject = function(includeInstance, msg) {
  var f, obj = {
host: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamEgress}
 */
proto.cloud_api.LivestreamEgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamEgress;
  return proto.cloud_api.LivestreamEgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamEgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamEgress}
 */
proto.cloud_api.LivestreamEgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamEgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamEgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamEgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.cloud_api.LivestreamEgress.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgress} returns this
 */
proto.cloud_api.LivestreamEgress.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamEgressCredentialsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamEgressCredentialsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
host: jspb.Message.getFieldWithDefault(msg, 1, ""),
roomName: jspb.Message.getFieldWithDefault(msg, 2, ""),
publisherIdentity: jspb.Message.getFieldWithDefault(msg, 3, ""),
trackName: jspb.Message.getFieldWithDefault(msg, 4, ""),
isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamEgressCredentialsRequest;
  return proto.cloud_api.LivestreamEgressCredentialsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamEgressCredentialsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherIdentity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamEgressCredentialsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamEgressCredentialsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoomName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublisherIdentity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string host = 1;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest} returns this
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string room_name = 2;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.getRoomName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest} returns this
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.setRoomName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string publisher_identity = 3;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.getPublisherIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest} returns this
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.setPublisherIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string track_name = 4;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest} returns this
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_admin = 5;
 * @return {boolean}
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.LivestreamEgressCredentialsRequest} returns this
 */
proto.cloud_api.LivestreamEgressCredentialsRequest.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamEgressCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamEgressCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamEgressCredentialsResponse}
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamEgressCredentialsResponse;
  return proto.cloud_api.LivestreamEgressCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamEgressCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamEgressCredentialsResponse}
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamEgressCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamEgressCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressCredentialsResponse} returns this
 */
proto.cloud_api.LivestreamEgressCredentialsResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamEgressValidateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamEgressValidateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressValidateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
roomName: jspb.Message.getFieldWithDefault(msg, 1, ""),
publisherIdentity: jspb.Message.getFieldWithDefault(msg, 2, ""),
trackName: jspb.Message.getFieldWithDefault(msg, 3, ""),
tokenUuid: jspb.Message.getFieldWithDefault(msg, 4, ""),
tokenSecret: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest}
 */
proto.cloud_api.LivestreamEgressValidateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamEgressValidateRequest;
  return proto.cloud_api.LivestreamEgressValidateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamEgressValidateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest}
 */
proto.cloud_api.LivestreamEgressValidateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoomName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublisherIdentity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenUuid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenSecret(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamEgressValidateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamEgressValidateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressValidateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoomName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublisherIdentity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTokenUuid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTokenSecret();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string room_name = 1;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.getRoomName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest} returns this
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.setRoomName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string publisher_identity = 2;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.getPublisherIdentity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest} returns this
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.setPublisherIdentity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string track_name = 3;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest} returns this
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string token_uuid = 4;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.getTokenUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest} returns this
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.setTokenUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string token_secret = 5;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.getTokenSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateRequest} returns this
 */
proto.cloud_api.LivestreamEgressValidateRequest.prototype.setTokenSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.LivestreamEgressValidateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.LivestreamEgressValidateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.LivestreamEgressValidateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressValidateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
tokenSecret: jspb.Message.getFieldWithDefault(msg, 1, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.LivestreamEgressValidateResponse}
 */
proto.cloud_api.LivestreamEgressValidateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.LivestreamEgressValidateResponse;
  return proto.cloud_api.LivestreamEgressValidateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.LivestreamEgressValidateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.LivestreamEgressValidateResponse}
 */
proto.cloud_api.LivestreamEgressValidateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenSecret(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.LivestreamEgressValidateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.LivestreamEgressValidateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.LivestreamEgressValidateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.LivestreamEgressValidateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenSecret();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string token_secret = 1;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateResponse.prototype.getTokenSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateResponse} returns this
 */
proto.cloud_api.LivestreamEgressValidateResponse.prototype.setTokenSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.cloud_api.LivestreamEgressValidateResponse.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.LivestreamEgressValidateResponse} returns this
 */
proto.cloud_api.LivestreamEgressValidateResponse.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.cloud_api);
