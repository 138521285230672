// source: pbtypes/gen/scan_planner/coverage_mesh_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_calibration_single_camera_calibration_pb = require('../../../pbtypes/gen/calibration/single_camera_calibration_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_single_camera_calibration_pb);
var pbtypes_gen_scan_planner_coverage_mesh_component_pb = require('../../../pbtypes/gen/scan_planner/coverage_mesh_component_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_coverage_mesh_component_pb);
var pbtypes_gen_scan_planner_expanded_polygon_prism_pb = require('../../../pbtypes/gen/scan_planner/expanded_polygon_prism_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_expanded_polygon_prism_pb);
var pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb = require('../../../pbtypes/gen/scan_planner/optional_expanded_polygon_prism_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb);
goog.exportSymbol('proto.scan_planner.CoverageMeshRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_planner.CoverageMeshRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scan_planner.CoverageMeshRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_planner.CoverageMeshRequest.displayName = 'proto.scan_planner.CoverageMeshRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_planner.CoverageMeshRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_planner.CoverageMeshRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_planner.CoverageMeshRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.CoverageMeshRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
photoCamera: (f = msg.getPhotoCamera()) && pbtypes_gen_calibration_single_camera_calibration_pb.SingleCameraCalibration.toObject(includeInstance, f),
coverageMeshComponent: (f = msg.getCoverageMeshComponent()) && pbtypes_gen_scan_planner_coverage_mesh_component_pb.CoverageMeshComponent.toObject(includeInstance, f),
expandedPolygonPrism: (f = msg.getExpandedPolygonPrism()) && pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism.toObject(includeInstance, f),
noScanVolume: (f = msg.getNoScanVolume()) && pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism.toObject(includeInstance, f),
resetCoverage: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
publishMesh: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_planner.CoverageMeshRequest}
 */
proto.scan_planner.CoverageMeshRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_planner.CoverageMeshRequest;
  return proto.scan_planner.CoverageMeshRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_planner.CoverageMeshRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_planner.CoverageMeshRequest}
 */
proto.scan_planner.CoverageMeshRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = new pbtypes_gen_calibration_single_camera_calibration_pb.SingleCameraCalibration;
      reader.readMessage(value,pbtypes_gen_calibration_single_camera_calibration_pb.SingleCameraCalibration.deserializeBinaryFromReader);
      msg.setPhotoCamera(value);
      break;
    case 3:
      var value = new pbtypes_gen_scan_planner_coverage_mesh_component_pb.CoverageMeshComponent;
      reader.readMessage(value,pbtypes_gen_scan_planner_coverage_mesh_component_pb.CoverageMeshComponent.deserializeBinaryFromReader);
      msg.setCoverageMeshComponent(value);
      break;
    case 4:
      var value = new pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism;
      reader.readMessage(value,pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism.deserializeBinaryFromReader);
      msg.setExpandedPolygonPrism(value);
      break;
    case 7:
      var value = new pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism;
      reader.readMessage(value,pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism.deserializeBinaryFromReader);
      msg.setNoScanVolume(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResetCoverage(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublishMesh(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_planner.CoverageMeshRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_planner.CoverageMeshRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_planner.CoverageMeshRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.CoverageMeshRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getPhotoCamera();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_calibration_single_camera_calibration_pb.SingleCameraCalibration.serializeBinaryToWriter
    );
  }
  f = message.getCoverageMeshComponent();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_scan_planner_coverage_mesh_component_pb.CoverageMeshComponent.serializeBinaryToWriter
    );
  }
  f = message.getExpandedPolygonPrism();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism.serializeBinaryToWriter
    );
  }
  f = message.getNoScanVolume();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism.serializeBinaryToWriter
    );
  }
  f = message.getResetCoverage();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPublishMesh();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional sint64 utime = 1;
 * @return {number}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional calibration.SingleCameraCalibration photo_camera = 2;
 * @return {?proto.calibration.SingleCameraCalibration}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getPhotoCamera = function() {
  return /** @type{?proto.calibration.SingleCameraCalibration} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_single_camera_calibration_pb.SingleCameraCalibration, 2));
};


/**
 * @param {?proto.calibration.SingleCameraCalibration|undefined} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
*/
proto.scan_planner.CoverageMeshRequest.prototype.setPhotoCamera = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.clearPhotoCamera = function() {
  return this.setPhotoCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.CoverageMeshRequest.prototype.hasPhotoCamera = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CoverageMeshComponent coverage_mesh_component = 3;
 * @return {?proto.scan_planner.CoverageMeshComponent}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getCoverageMeshComponent = function() {
  return /** @type{?proto.scan_planner.CoverageMeshComponent} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_coverage_mesh_component_pb.CoverageMeshComponent, 3));
};


/**
 * @param {?proto.scan_planner.CoverageMeshComponent|undefined} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
*/
proto.scan_planner.CoverageMeshRequest.prototype.setCoverageMeshComponent = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.clearCoverageMeshComponent = function() {
  return this.setCoverageMeshComponent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.CoverageMeshRequest.prototype.hasCoverageMeshComponent = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ExpandedPolygonPrism expanded_polygon_prism = 4;
 * @return {?proto.scan_planner.ExpandedPolygonPrism}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getExpandedPolygonPrism = function() {
  return /** @type{?proto.scan_planner.ExpandedPolygonPrism} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism, 4));
};


/**
 * @param {?proto.scan_planner.ExpandedPolygonPrism|undefined} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
*/
proto.scan_planner.CoverageMeshRequest.prototype.setExpandedPolygonPrism = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.clearExpandedPolygonPrism = function() {
  return this.setExpandedPolygonPrism(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.CoverageMeshRequest.prototype.hasExpandedPolygonPrism = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OptionalExpandedPolygonPrism no_scan_volume = 7;
 * @return {?proto.scan_planner.OptionalExpandedPolygonPrism}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getNoScanVolume = function() {
  return /** @type{?proto.scan_planner.OptionalExpandedPolygonPrism} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism, 7));
};


/**
 * @param {?proto.scan_planner.OptionalExpandedPolygonPrism|undefined} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
*/
proto.scan_planner.CoverageMeshRequest.prototype.setNoScanVolume = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.clearNoScanVolume = function() {
  return this.setNoScanVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.CoverageMeshRequest.prototype.hasNoScanVolume = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool reset_coverage = 5;
 * @return {boolean}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getResetCoverage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.setResetCoverage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool publish_mesh = 6;
 * @return {boolean}
 */
proto.scan_planner.CoverageMeshRequest.prototype.getPublishMesh = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_planner.CoverageMeshRequest} returns this
 */
proto.scan_planner.CoverageMeshRequest.prototype.setPublishMesh = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


goog.object.extend(exports, proto.scan_planner);
