// source: pbtypes/gen/calibration/single_camera_calibration.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_gen_calibration_auto_focus_data_pb = require('../../../pbtypes/gen/calibration/auto_focus_data_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_auto_focus_data_pb);
var pbtypes_gen_calibration_camera_intrinsics_pb = require('../../../pbtypes/gen/calibration/camera_intrinsics_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_camera_intrinsics_pb);
var pbtypes_gen_calibration_camera_undistortion_pb = require('../../../pbtypes/gen/calibration/camera_undistortion_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_camera_undistortion_pb);
var pbtypes_gen_calibration_image_configuration_pb = require('../../../pbtypes/gen/calibration/image_configuration_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_image_configuration_pb);
var pbtypes_gen_calibration_temperature_model_info_pb = require('../../../pbtypes/gen/calibration/temperature_model_info_pb.js');
goog.object.extend(proto, pbtypes_gen_calibration_temperature_model_info_pb);
goog.exportSymbol('proto.calibration.SingleCameraCalibration', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.calibration.SingleCameraCalibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.calibration.SingleCameraCalibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.calibration.SingleCameraCalibration.displayName = 'proto.calibration.SingleCameraCalibration';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.calibration.SingleCameraCalibration.prototype.toObject = function(opt_includeInstance) {
  return proto.calibration.SingleCameraCalibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.calibration.SingleCameraCalibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.calibration.SingleCameraCalibration.toObject = function(includeInstance, msg) {
  var f, obj = {
hardware180Rotate: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
image: (f = msg.getImage()) && pbtypes_gen_calibration_image_configuration_pb.ImageConfiguration.toObject(includeInstance, f),
imuTCam: (f = msg.getImuTCam()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
intrinsics: (f = msg.getIntrinsics()) && pbtypes_gen_calibration_camera_intrinsics_pb.CameraIntrinsics.toObject(includeInstance, f),
serialNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
undistortion: (f = msg.getUndistortion()) && pbtypes_gen_calibration_camera_undistortion_pb.CameraUndistortion.toObject(includeInstance, f),
autoFocusData: (f = msg.getAutoFocusData()) && pbtypes_gen_calibration_auto_focus_data_pb.AutoFocusData.toObject(includeInstance, f),
temperatureModelInfo: (f = msg.getTemperatureModelInfo()) && pbtypes_gen_calibration_temperature_model_info_pb.TemperatureModelInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.calibration.SingleCameraCalibration}
 */
proto.calibration.SingleCameraCalibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.calibration.SingleCameraCalibration;
  return proto.calibration.SingleCameraCalibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.calibration.SingleCameraCalibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.calibration.SingleCameraCalibration}
 */
proto.calibration.SingleCameraCalibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHardware180Rotate(value);
      break;
    case 3:
      var value = new pbtypes_gen_calibration_image_configuration_pb.ImageConfiguration;
      reader.readMessage(value,pbtypes_gen_calibration_image_configuration_pb.ImageConfiguration.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setImuTCam(value);
      break;
    case 5:
      var value = new pbtypes_gen_calibration_camera_intrinsics_pb.CameraIntrinsics;
      reader.readMessage(value,pbtypes_gen_calibration_camera_intrinsics_pb.CameraIntrinsics.deserializeBinaryFromReader);
      msg.setIntrinsics(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setSerialNumber(value);
      break;
    case 6:
      var value = new pbtypes_gen_calibration_camera_undistortion_pb.CameraUndistortion;
      reader.readMessage(value,pbtypes_gen_calibration_camera_undistortion_pb.CameraUndistortion.deserializeBinaryFromReader);
      msg.setUndistortion(value);
      break;
    case 7:
      var value = new pbtypes_gen_calibration_auto_focus_data_pb.AutoFocusData;
      reader.readMessage(value,pbtypes_gen_calibration_auto_focus_data_pb.AutoFocusData.deserializeBinaryFromReader);
      msg.setAutoFocusData(value);
      break;
    case 8:
      var value = new pbtypes_gen_calibration_temperature_model_info_pb.TemperatureModelInfo;
      reader.readMessage(value,pbtypes_gen_calibration_temperature_model_info_pb.TemperatureModelInfo.deserializeBinaryFromReader);
      msg.setTemperatureModelInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.calibration.SingleCameraCalibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.calibration.SingleCameraCalibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.calibration.SingleCameraCalibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.calibration.SingleCameraCalibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardware180Rotate();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_calibration_image_configuration_pb.ImageConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getImuTCam();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getIntrinsics();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_calibration_camera_intrinsics_pb.CameraIntrinsics.serializeBinaryToWriter
    );
  }
  f = message.getSerialNumber();
  if (f !== 0) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = message.getUndistortion();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_calibration_camera_undistortion_pb.CameraUndistortion.serializeBinaryToWriter
    );
  }
  f = message.getAutoFocusData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_calibration_auto_focus_data_pb.AutoFocusData.serializeBinaryToWriter
    );
  }
  f = message.getTemperatureModelInfo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_gen_calibration_temperature_model_info_pb.TemperatureModelInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool hardware_180_rotate = 2;
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.getHardware180Rotate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.setHardware180Rotate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional ImageConfiguration image = 3;
 * @return {?proto.calibration.ImageConfiguration}
 */
proto.calibration.SingleCameraCalibration.prototype.getImage = function() {
  return /** @type{?proto.calibration.ImageConfiguration} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_image_configuration_pb.ImageConfiguration, 3));
};


/**
 * @param {?proto.calibration.ImageConfiguration|undefined} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
*/
proto.calibration.SingleCameraCalibration.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.hasImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional body.TransPb imu_t_cam = 4;
 * @return {?proto.body.TransPb}
 */
proto.calibration.SingleCameraCalibration.prototype.getImuTCam = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 4));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
*/
proto.calibration.SingleCameraCalibration.prototype.setImuTCam = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.clearImuTCam = function() {
  return this.setImuTCam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.hasImuTCam = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CameraIntrinsics intrinsics = 5;
 * @return {?proto.calibration.CameraIntrinsics}
 */
proto.calibration.SingleCameraCalibration.prototype.getIntrinsics = function() {
  return /** @type{?proto.calibration.CameraIntrinsics} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_camera_intrinsics_pb.CameraIntrinsics, 5));
};


/**
 * @param {?proto.calibration.CameraIntrinsics|undefined} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
*/
proto.calibration.SingleCameraCalibration.prototype.setIntrinsics = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.clearIntrinsics = function() {
  return this.setIntrinsics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.hasIntrinsics = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional sint32 serial_number = 1;
 * @return {number}
 */
proto.calibration.SingleCameraCalibration.prototype.getSerialNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CameraUndistortion undistortion = 6;
 * @return {?proto.calibration.CameraUndistortion}
 */
proto.calibration.SingleCameraCalibration.prototype.getUndistortion = function() {
  return /** @type{?proto.calibration.CameraUndistortion} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_camera_undistortion_pb.CameraUndistortion, 6));
};


/**
 * @param {?proto.calibration.CameraUndistortion|undefined} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
*/
proto.calibration.SingleCameraCalibration.prototype.setUndistortion = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.clearUndistortion = function() {
  return this.setUndistortion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.hasUndistortion = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional AutoFocusData auto_focus_data = 7;
 * @return {?proto.calibration.AutoFocusData}
 */
proto.calibration.SingleCameraCalibration.prototype.getAutoFocusData = function() {
  return /** @type{?proto.calibration.AutoFocusData} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_auto_focus_data_pb.AutoFocusData, 7));
};


/**
 * @param {?proto.calibration.AutoFocusData|undefined} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
*/
proto.calibration.SingleCameraCalibration.prototype.setAutoFocusData = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.clearAutoFocusData = function() {
  return this.setAutoFocusData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.hasAutoFocusData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TemperatureModelInfo temperature_model_info = 8;
 * @return {?proto.calibration.TemperatureModelInfo}
 */
proto.calibration.SingleCameraCalibration.prototype.getTemperatureModelInfo = function() {
  return /** @type{?proto.calibration.TemperatureModelInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_calibration_temperature_model_info_pb.TemperatureModelInfo, 8));
};


/**
 * @param {?proto.calibration.TemperatureModelInfo|undefined} value
 * @return {!proto.calibration.SingleCameraCalibration} returns this
*/
proto.calibration.SingleCameraCalibration.prototype.setTemperatureModelInfo = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.calibration.SingleCameraCalibration} returns this
 */
proto.calibration.SingleCameraCalibration.prototype.clearTemperatureModelInfo = function() {
  return this.setTemperatureModelInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.calibration.SingleCameraCalibration.prototype.hasTemperatureModelInfo = function() {
  return jspb.Message.getField(this, 8) != null;
};


goog.object.extend(exports, proto.calibration);
