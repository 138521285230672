// source: pbtypes/gen/scan_output/scan_output.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_gen_scan_output_scan_data_segment_pb = require('../../../pbtypes/gen/scan_output/scan_data_segment_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_output_scan_data_segment_pb);
var pbtypes_gen_scan_output_scan_mesh_pb = require('../../../pbtypes/gen/scan_output/scan_mesh_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_output_scan_mesh_pb);
var pbtypes_gen_scan_planner_coverage_mesh_request_pb = require('../../../pbtypes/gen/scan_planner/coverage_mesh_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_coverage_mesh_request_pb);
var pbtypes_gen_scan_planner_expanded_polygon_prism_pb = require('../../../pbtypes/gen/scan_planner/expanded_polygon_prism_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_expanded_polygon_prism_pb);
var pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb = require('../../../pbtypes/gen/scan_planner/optional_expanded_polygon_prism_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb);
var pbtypes_gen_scan_planner_polygon_prism_pb = require('../../../pbtypes/gen/scan_planner/polygon_prism_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_polygon_prism_pb);
var pbtypes_gen_scan_planner_scan_settings_pb = require('../../../pbtypes/gen/scan_planner/scan_settings_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_scan_settings_pb);
var pbtypes_gen_skills_gps_waypoint_pb = require('../../../pbtypes/gen/skills/gps_waypoint_pb.js');
goog.object.extend(proto, pbtypes_gen_skills_gps_waypoint_pb);
var pbtypes_gen_surface_scan_inspection_type_pb = require('../../../pbtypes/gen/surface_scan/inspection_type_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_inspection_type_pb);
var pbtypes_gen_surface_scan_scan_mode_pb = require('../../../pbtypes/gen/surface_scan/scan_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_scan_mode_pb);
var pbtypes_gen_surface_scan_specification_selection_pb = require('../../../pbtypes/gen/surface_scan/specification_selection_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_specification_selection_pb);
var pbtypes_gen_surface_scan_surface_scan_analytics_pb = require('../../../pbtypes/gen/surface_scan/surface_scan_analytics_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_surface_scan_analytics_pb);
var pbtypes_gen_surface_scan_viewpoints_state_pb = require('../../../pbtypes/gen/surface_scan/viewpoints_state_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_viewpoints_state_pb);
goog.exportSymbol('proto.scan_output.ScanOutput', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_output.ScanOutput = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scan_output.ScanOutput.repeatedFields_, null);
};
goog.inherits(proto.scan_output.ScanOutput, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_output.ScanOutput.displayName = 'proto.scan_output.ScanOutput';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scan_output.ScanOutput.repeatedFields_ = [10,8,31];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_output.ScanOutput.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_output.ScanOutput.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_output.ScanOutput} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_output.ScanOutput.toObject = function(includeInstance, msg) {
  var f, obj = {
scanSkillStateId: jspb.Message.getFieldWithDefault(msg, 1, ""),
organizationUuid: jspb.Message.getFieldWithDefault(msg, 36, ""),
taskInputId: jspb.Message.getFieldWithDefault(msg, 17, ""),
taskOutputId: jspb.Message.getFieldWithDefault(msg, 11, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
siteName: jspb.Message.getFieldWithDefault(msg, 21, ""),
description: jspb.Message.getFieldWithDefault(msg, 3, ""),
datetime: jspb.Message.getFieldWithDefault(msg, 22, ""),
creationUclock: jspb.Message.getFieldWithDefault(msg, 4, 0),
scanDataSegmentsList: jspb.Message.toObjectList(msg.getScanDataSegmentsList(),
    pbtypes_gen_scan_output_scan_data_segment_pb.ScanDataSegment.toObject, includeInstance),
inFlightCoverageMeshRequest: (f = msg.getInFlightCoverageMeshRequest()) && pbtypes_gen_scan_planner_coverage_mesh_request_pb.CoverageMeshRequest.toObject(includeInstance, f),
volumeIntent: (f = msg.getVolumeIntent()) && pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism.toObject(includeInstance, f),
noScanVolume: (f = msg.getNoScanVolume()) && pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism.toObject(includeInstance, f),
expandedPolygonPrism: (f = msg.getExpandedPolygonPrism()) && pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism.toObject(includeInstance, f),
groundHeight: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
gpsTRelocalization: (f = msg.getGpsTRelocalization()) && pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.toObject(includeInstance, f),
gpsLinearizationOrigin: (f = msg.getGpsLinearizationOrigin()) && pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.toObject(includeInstance, f),
gpsScanPosition: (f = msg.getGpsScanPosition()) && pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.toObject(includeInstance, f),
globalMapTRelocalization: (f = msg.getGlobalMapTRelocalization()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
globalTGlobalMap: (f = msg.getGlobalTGlobalMap()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
meshesList: jspb.Message.toObjectList(msg.getMeshesList(),
    pbtypes_gen_scan_output_scan_mesh_pb.ScanMesh.toObject, includeInstance),
defaultGps: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
customGpsCorrespondenceEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
scanSettings: (f = msg.getScanSettings()) && pbtypes_gen_scan_planner_scan_settings_pb.ScanSettings.toObject(includeInstance, f),
viewpointsState: (f = msg.getViewpointsState()) && pbtypes_gen_surface_scan_viewpoints_state_pb.ViewpointsState.toObject(includeInstance, f),
photoSavePath: jspb.Message.getFieldWithDefault(msg, 23, ""),
isImperial: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
gpsLinearizationTGlobalMap: (f = msg.getGpsLinearizationTGlobalMap()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
json: jspb.Message.getFieldWithDefault(msg, 9, ""),
scanMode: jspb.Message.getFieldWithDefault(msg, 29, 0),
specificationSelection: jspb.Message.getFieldWithDefault(msg, 30, 0),
inspectionTypesList: (f = jspb.Message.getRepeatedField(msg, 31)) == null ? undefined : f,
analytics: (f = msg.getAnalytics()) && pbtypes_gen_surface_scan_surface_scan_analytics_pb.SurfaceScanAnalytics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_output.ScanOutput}
 */
proto.scan_output.ScanOutput.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_output.ScanOutput;
  return proto.scan_output.ScanOutput.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_output.ScanOutput} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_output.ScanOutput}
 */
proto.scan_output.ScanOutput.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setScanSkillStateId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationUuid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskInputId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskOutputId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setCreationUclock(value);
      break;
    case 10:
      var value = new pbtypes_gen_scan_output_scan_data_segment_pb.ScanDataSegment;
      reader.readMessage(value,pbtypes_gen_scan_output_scan_data_segment_pb.ScanDataSegment.deserializeBinaryFromReader);
      msg.addScanDataSegments(value);
      break;
    case 33:
      var value = new pbtypes_gen_scan_planner_coverage_mesh_request_pb.CoverageMeshRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_coverage_mesh_request_pb.CoverageMeshRequest.deserializeBinaryFromReader);
      msg.setInFlightCoverageMeshRequest(value);
      break;
    case 7:
      var value = new pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism;
      reader.readMessage(value,pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism.deserializeBinaryFromReader);
      msg.setVolumeIntent(value);
      break;
    case 34:
      var value = new pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism;
      reader.readMessage(value,pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism.deserializeBinaryFromReader);
      msg.setNoScanVolume(value);
      break;
    case 28:
      var value = new pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism;
      reader.readMessage(value,pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism.deserializeBinaryFromReader);
      msg.setExpandedPolygonPrism(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGroundHeight(value);
      break;
    case 13:
      var value = new pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint;
      reader.readMessage(value,pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.deserializeBinaryFromReader);
      msg.setGpsTRelocalization(value);
      break;
    case 26:
      var value = new pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint;
      reader.readMessage(value,pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.deserializeBinaryFromReader);
      msg.setGpsLinearizationOrigin(value);
      break;
    case 27:
      var value = new pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint;
      reader.readMessage(value,pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.deserializeBinaryFromReader);
      msg.setGpsScanPosition(value);
      break;
    case 14:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGlobalMapTRelocalization(value);
      break;
    case 15:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGlobalTGlobalMap(value);
      break;
    case 8:
      var value = new pbtypes_gen_scan_output_scan_mesh_pb.ScanMesh;
      reader.readMessage(value,pbtypes_gen_scan_output_scan_mesh_pb.ScanMesh.deserializeBinaryFromReader);
      msg.addMeshes(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaultGps(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomGpsCorrespondenceEnabled(value);
      break;
    case 19:
      var value = new pbtypes_gen_scan_planner_scan_settings_pb.ScanSettings;
      reader.readMessage(value,pbtypes_gen_scan_planner_scan_settings_pb.ScanSettings.deserializeBinaryFromReader);
      msg.setScanSettings(value);
      break;
    case 20:
      var value = new pbtypes_gen_surface_scan_viewpoints_state_pb.ViewpointsState;
      reader.readMessage(value,pbtypes_gen_surface_scan_viewpoints_state_pb.ViewpointsState.deserializeBinaryFromReader);
      msg.setViewpointsState(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhotoSavePath(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsImperial(value);
      break;
    case 25:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGpsLinearizationTGlobalMap(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    case 29:
      var value = /** @type {!proto.surface_scan.ScanMode.Enum} */ (reader.readEnum());
      msg.setScanMode(value);
      break;
    case 30:
      var value = /** @type {!proto.surface_scan.SpecificationSelection.Enum} */ (reader.readEnum());
      msg.setSpecificationSelection(value);
      break;
    case 31:
      var values = /** @type {!Array<!proto.surface_scan.InspectionType.Enum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInspectionTypes(values[i]);
      }
      break;
    case 32:
      var value = new pbtypes_gen_surface_scan_surface_scan_analytics_pb.SurfaceScanAnalytics;
      reader.readMessage(value,pbtypes_gen_surface_scan_surface_scan_analytics_pb.SurfaceScanAnalytics.deserializeBinaryFromReader);
      msg.setAnalytics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_output.ScanOutput.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_output.ScanOutput.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_output.ScanOutput} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_output.ScanOutput.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScanSkillStateId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationUuid();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getTaskInputId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTaskOutputId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getCreationUclock();
  if (f !== 0) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = message.getScanDataSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      pbtypes_gen_scan_output_scan_data_segment_pb.ScanDataSegment.serializeBinaryToWriter
    );
  }
  f = message.getInFlightCoverageMeshRequest();
  if (f != null) {
    writer.writeMessage(
      33,
      f,
      pbtypes_gen_scan_planner_coverage_mesh_request_pb.CoverageMeshRequest.serializeBinaryToWriter
    );
  }
  f = message.getVolumeIntent();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism.serializeBinaryToWriter
    );
  }
  f = message.getNoScanVolume();
  if (f != null) {
    writer.writeMessage(
      34,
      f,
      pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism.serializeBinaryToWriter
    );
  }
  f = message.getExpandedPolygonPrism();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism.serializeBinaryToWriter
    );
  }
  f = message.getGroundHeight();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getGpsTRelocalization();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getGpsLinearizationOrigin();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getGpsScanPosition();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getGlobalMapTRelocalization();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getGlobalTGlobalMap();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getMeshesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      pbtypes_gen_scan_output_scan_mesh_pb.ScanMesh.serializeBinaryToWriter
    );
  }
  f = message.getDefaultGps();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getCustomGpsCorrespondenceEnabled();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getScanSettings();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      pbtypes_gen_scan_planner_scan_settings_pb.ScanSettings.serializeBinaryToWriter
    );
  }
  f = message.getViewpointsState();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      pbtypes_gen_surface_scan_viewpoints_state_pb.ViewpointsState.serializeBinaryToWriter
    );
  }
  f = message.getPhotoSavePath();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIsImperial();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getGpsLinearizationTGlobalMap();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getScanMode();
  if (f !== 0.0) {
    writer.writeEnum(
      29,
      f
    );
  }
  f = message.getSpecificationSelection();
  if (f !== 0.0) {
    writer.writeEnum(
      30,
      f
    );
  }
  f = message.getInspectionTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      31,
      f
    );
  }
  f = message.getAnalytics();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      pbtypes_gen_surface_scan_surface_scan_analytics_pb.SurfaceScanAnalytics.serializeBinaryToWriter
    );
  }
};


/**
 * optional string scan_skill_state_id = 1;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getScanSkillStateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setScanSkillStateId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_uuid = 36;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getOrganizationUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setOrganizationUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string task_input_id = 17;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getTaskInputId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setTaskInputId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string task_output_id = 11;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getTaskOutputId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setTaskOutputId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string site_name = 21;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setSiteName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string datetime = 22;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getDatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional sint64 creation_uclock = 4;
 * @return {number}
 */
proto.scan_output.ScanOutput.prototype.getCreationUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setCreationUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated ScanDataSegment scan_data_segments = 10;
 * @return {!Array<!proto.scan_output.ScanDataSegment>}
 */
proto.scan_output.ScanOutput.prototype.getScanDataSegmentsList = function() {
  return /** @type{!Array<!proto.scan_output.ScanDataSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_scan_output_scan_data_segment_pb.ScanDataSegment, 10));
};


/**
 * @param {!Array<!proto.scan_output.ScanDataSegment>} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setScanDataSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.scan_output.ScanDataSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scan_output.ScanDataSegment}
 */
proto.scan_output.ScanOutput.prototype.addScanDataSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.scan_output.ScanDataSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearScanDataSegmentsList = function() {
  return this.setScanDataSegmentsList([]);
};


/**
 * optional scan_planner.CoverageMeshRequest in_flight_coverage_mesh_request = 33;
 * @return {?proto.scan_planner.CoverageMeshRequest}
 */
proto.scan_output.ScanOutput.prototype.getInFlightCoverageMeshRequest = function() {
  return /** @type{?proto.scan_planner.CoverageMeshRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_coverage_mesh_request_pb.CoverageMeshRequest, 33));
};


/**
 * @param {?proto.scan_planner.CoverageMeshRequest|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setInFlightCoverageMeshRequest = function(value) {
  return jspb.Message.setWrapperField(this, 33, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearInFlightCoverageMeshRequest = function() {
  return this.setInFlightCoverageMeshRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasInFlightCoverageMeshRequest = function() {
  return jspb.Message.getField(this, 33) != null;
};


/**
 * optional scan_planner.PolygonPrism volume_intent = 7;
 * @return {?proto.scan_planner.PolygonPrism}
 */
proto.scan_output.ScanOutput.prototype.getVolumeIntent = function() {
  return /** @type{?proto.scan_planner.PolygonPrism} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_polygon_prism_pb.PolygonPrism, 7));
};


/**
 * @param {?proto.scan_planner.PolygonPrism|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setVolumeIntent = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearVolumeIntent = function() {
  return this.setVolumeIntent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasVolumeIntent = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional scan_planner.OptionalExpandedPolygonPrism no_scan_volume = 34;
 * @return {?proto.scan_planner.OptionalExpandedPolygonPrism}
 */
proto.scan_output.ScanOutput.prototype.getNoScanVolume = function() {
  return /** @type{?proto.scan_planner.OptionalExpandedPolygonPrism} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_optional_expanded_polygon_prism_pb.OptionalExpandedPolygonPrism, 34));
};


/**
 * @param {?proto.scan_planner.OptionalExpandedPolygonPrism|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setNoScanVolume = function(value) {
  return jspb.Message.setWrapperField(this, 34, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearNoScanVolume = function() {
  return this.setNoScanVolume(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasNoScanVolume = function() {
  return jspb.Message.getField(this, 34) != null;
};


/**
 * optional scan_planner.ExpandedPolygonPrism expanded_polygon_prism = 28;
 * @return {?proto.scan_planner.ExpandedPolygonPrism}
 */
proto.scan_output.ScanOutput.prototype.getExpandedPolygonPrism = function() {
  return /** @type{?proto.scan_planner.ExpandedPolygonPrism} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_expanded_polygon_prism_pb.ExpandedPolygonPrism, 28));
};


/**
 * @param {?proto.scan_planner.ExpandedPolygonPrism|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setExpandedPolygonPrism = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearExpandedPolygonPrism = function() {
  return this.setExpandedPolygonPrism(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasExpandedPolygonPrism = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional float ground_height = 12;
 * @return {number}
 */
proto.scan_output.ScanOutput.prototype.getGroundHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setGroundHeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional skills.GpsWaypoint gps_t_relocalization = 13;
 * @return {?proto.skills.GpsWaypoint}
 */
proto.scan_output.ScanOutput.prototype.getGpsTRelocalization = function() {
  return /** @type{?proto.skills.GpsWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint, 13));
};


/**
 * @param {?proto.skills.GpsWaypoint|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setGpsTRelocalization = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearGpsTRelocalization = function() {
  return this.setGpsTRelocalization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasGpsTRelocalization = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional skills.GpsWaypoint gps_linearization_origin = 26;
 * @return {?proto.skills.GpsWaypoint}
 */
proto.scan_output.ScanOutput.prototype.getGpsLinearizationOrigin = function() {
  return /** @type{?proto.skills.GpsWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint, 26));
};


/**
 * @param {?proto.skills.GpsWaypoint|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setGpsLinearizationOrigin = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearGpsLinearizationOrigin = function() {
  return this.setGpsLinearizationOrigin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasGpsLinearizationOrigin = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional skills.GpsWaypoint gps_scan_position = 27;
 * @return {?proto.skills.GpsWaypoint}
 */
proto.scan_output.ScanOutput.prototype.getGpsScanPosition = function() {
  return /** @type{?proto.skills.GpsWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_skills_gps_waypoint_pb.GpsWaypoint, 27));
};


/**
 * @param {?proto.skills.GpsWaypoint|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setGpsScanPosition = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearGpsScanPosition = function() {
  return this.setGpsScanPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasGpsScanPosition = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional body.TransPb global_map_t_relocalization = 14;
 * @return {?proto.body.TransPb}
 */
proto.scan_output.ScanOutput.prototype.getGlobalMapTRelocalization = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 14));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setGlobalMapTRelocalization = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearGlobalMapTRelocalization = function() {
  return this.setGlobalMapTRelocalization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasGlobalMapTRelocalization = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional body.TransPb global_t_global_map = 15;
 * @return {?proto.body.TransPb}
 */
proto.scan_output.ScanOutput.prototype.getGlobalTGlobalMap = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 15));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setGlobalTGlobalMap = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearGlobalTGlobalMap = function() {
  return this.setGlobalTGlobalMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasGlobalTGlobalMap = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated ScanMesh meshes = 8;
 * @return {!Array<!proto.scan_output.ScanMesh>}
 */
proto.scan_output.ScanOutput.prototype.getMeshesList = function() {
  return /** @type{!Array<!proto.scan_output.ScanMesh>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_scan_output_scan_mesh_pb.ScanMesh, 8));
};


/**
 * @param {!Array<!proto.scan_output.ScanMesh>} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setMeshesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.scan_output.ScanMesh=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scan_output.ScanMesh}
 */
proto.scan_output.ScanOutput.prototype.addMeshes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.scan_output.ScanMesh, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearMeshesList = function() {
  return this.setMeshesList([]);
};


/**
 * optional bool default_gps = 18;
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.getDefaultGps = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setDefaultGps = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional bool custom_gps_correspondence_enabled = 35;
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.getCustomGpsCorrespondenceEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setCustomGpsCorrespondenceEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional scan_planner.ScanSettings scan_settings = 19;
 * @return {?proto.scan_planner.ScanSettings}
 */
proto.scan_output.ScanOutput.prototype.getScanSettings = function() {
  return /** @type{?proto.scan_planner.ScanSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_scan_settings_pb.ScanSettings, 19));
};


/**
 * @param {?proto.scan_planner.ScanSettings|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setScanSettings = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearScanSettings = function() {
  return this.setScanSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasScanSettings = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional surface_scan.ViewpointsState viewpoints_state = 20;
 * @return {?proto.surface_scan.ViewpointsState}
 */
proto.scan_output.ScanOutput.prototype.getViewpointsState = function() {
  return /** @type{?proto.surface_scan.ViewpointsState} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_surface_scan_viewpoints_state_pb.ViewpointsState, 20));
};


/**
 * @param {?proto.surface_scan.ViewpointsState|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setViewpointsState = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearViewpointsState = function() {
  return this.setViewpointsState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasViewpointsState = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string photo_save_path = 23;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getPhotoSavePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setPhotoSavePath = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional bool is_imperial = 24;
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.getIsImperial = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setIsImperial = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional body.TransPb gps_linearization_t_global_map = 25;
 * @return {?proto.body.TransPb}
 */
proto.scan_output.ScanOutput.prototype.getGpsLinearizationTGlobalMap = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 25));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setGpsLinearizationTGlobalMap = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearGpsLinearizationTGlobalMap = function() {
  return this.setGpsLinearizationTGlobalMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasGpsLinearizationTGlobalMap = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string json = 9;
 * @return {string}
 */
proto.scan_output.ScanOutput.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional surface_scan.ScanMode.Enum scan_mode = 29;
 * @return {!proto.surface_scan.ScanMode.Enum}
 */
proto.scan_output.ScanOutput.prototype.getScanMode = function() {
  return /** @type {!proto.surface_scan.ScanMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {!proto.surface_scan.ScanMode.Enum} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setScanMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 29, value);
};


/**
 * optional surface_scan.SpecificationSelection.Enum specification_selection = 30;
 * @return {!proto.surface_scan.SpecificationSelection.Enum}
 */
proto.scan_output.ScanOutput.prototype.getSpecificationSelection = function() {
  return /** @type {!proto.surface_scan.SpecificationSelection.Enum} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {!proto.surface_scan.SpecificationSelection.Enum} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setSpecificationSelection = function(value) {
  return jspb.Message.setProto3EnumField(this, 30, value);
};


/**
 * repeated surface_scan.InspectionType.Enum inspection_types = 31;
 * @return {!Array<!proto.surface_scan.InspectionType.Enum>}
 */
proto.scan_output.ScanOutput.prototype.getInspectionTypesList = function() {
  return /** @type {!Array<!proto.surface_scan.InspectionType.Enum>} */ (jspb.Message.getRepeatedField(this, 31));
};


/**
 * @param {!Array<!proto.surface_scan.InspectionType.Enum>} value
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.setInspectionTypesList = function(value) {
  return jspb.Message.setField(this, 31, value || []);
};


/**
 * @param {!proto.surface_scan.InspectionType.Enum} value
 * @param {number=} opt_index
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.addInspectionTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 31, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearInspectionTypesList = function() {
  return this.setInspectionTypesList([]);
};


/**
 * optional surface_scan.SurfaceScanAnalytics analytics = 32;
 * @return {?proto.surface_scan.SurfaceScanAnalytics}
 */
proto.scan_output.ScanOutput.prototype.getAnalytics = function() {
  return /** @type{?proto.surface_scan.SurfaceScanAnalytics} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_surface_scan_surface_scan_analytics_pb.SurfaceScanAnalytics, 32));
};


/**
 * @param {?proto.surface_scan.SurfaceScanAnalytics|undefined} value
 * @return {!proto.scan_output.ScanOutput} returns this
*/
proto.scan_output.ScanOutput.prototype.setAnalytics = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanOutput} returns this
 */
proto.scan_output.ScanOutput.prototype.clearAnalytics = function() {
  return this.setAnalytics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanOutput.prototype.hasAnalytics = function() {
  return jspb.Message.getField(this, 32) != null;
};


goog.object.extend(exports, proto.scan_output);
