// source: pbtypes/tools/cloud_api/simulator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
var pbtypes_tools_cloud_api_user_pb = require('../../../pbtypes/tools/cloud_api/user_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_user_pb);
goog.exportSymbol('proto.cloud_api.Simulator', null, global);
goog.exportSymbol('proto.cloud_api.Simulators', null, global);
goog.exportSymbol('proto.cloud_api.UpdateSimulatorRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Simulator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Simulator.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Simulator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Simulator.displayName = 'proto.cloud_api.Simulator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateSimulatorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateSimulatorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateSimulatorRequest.displayName = 'proto.cloud_api.UpdateSimulatorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Simulators = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Simulators.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Simulators, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Simulators.displayName = 'proto.cloud_api.Simulators';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Simulator.repeatedFields_ = [16,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Simulator.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Simulator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Simulator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Simulator.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
host: jspb.Message.getFieldWithDefault(msg, 3, ""),
hostname: jspb.Message.getFieldWithDefault(msg, 4, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 5, ""),
insecure: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
connectWithoutWifi: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
externalHttpPort: jspb.Message.getFieldWithDefault(msg, 8, 0),
httpPort: jspb.Message.getFieldWithDefault(msg, 9, 0),
udpPort: jspb.Message.getFieldWithDefault(msg, 10, 0),
defaultRunmode: jspb.Message.getFieldWithDefault(msg, 11, ""),
imageTag: jspb.Message.getFieldWithDefault(msg, 12, ""),
region: jspb.Message.getFieldWithDefault(msg, 13, ""),
pooled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
lastPooledAssignmentTime: jspb.Message.getFieldWithDefault(msg, 15, 0),
userEmailsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
usersList: jspb.Message.toObjectList(msg.getUsersList(),
    pbtypes_tools_cloud_api_user_pb.RelatedUser.toObject, includeInstance),
credentials: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Simulator}
 */
proto.cloud_api.Simulator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Simulator;
  return proto.cloud_api.Simulator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Simulator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Simulator}
 */
proto.cloud_api.Simulator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInsecure(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnectWithoutWifi(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExternalHttpPort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHttpPort(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUdpPort(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultRunmode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageTag(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPooled(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastPooledAssignmentTime(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserEmails(value);
      break;
    case 18:
      var value = new pbtypes_tools_cloud_api_user_pb.RelatedUser;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.RelatedUser.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Simulator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Simulator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Simulator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Simulator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInsecure();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getConnectWithoutWifi();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getExternalHttpPort();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHttpPort();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUdpPort();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDefaultRunmode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getImageTag();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPooled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getLastPooledAssignmentTime();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getUserEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      pbtypes_tools_cloud_api_user_pb.RelatedUser.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string host = 3;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string hostname = 4;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string vehicle_id = 5;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool insecure = 6;
 * @return {boolean}
 */
proto.cloud_api.Simulator.prototype.getInsecure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setInsecure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool connect_without_wifi = 7;
 * @return {boolean}
 */
proto.cloud_api.Simulator.prototype.getConnectWithoutWifi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setConnectWithoutWifi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 external_http_port = 8;
 * @return {number}
 */
proto.cloud_api.Simulator.prototype.getExternalHttpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setExternalHttpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 http_port = 9;
 * @return {number}
 */
proto.cloud_api.Simulator.prototype.getHttpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setHttpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 udp_port = 10;
 * @return {number}
 */
proto.cloud_api.Simulator.prototype.getUdpPort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setUdpPort = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string default_runmode = 11;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getDefaultRunmode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setDefaultRunmode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string image_tag = 12;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getImageTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setImageTag = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string region = 13;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool pooled = 14;
 * @return {boolean}
 */
proto.cloud_api.Simulator.prototype.getPooled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setPooled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 last_pooled_assignment_time = 15;
 * @return {number}
 */
proto.cloud_api.Simulator.prototype.getLastPooledAssignmentTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setLastPooledAssignmentTime = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated string user_emails = 16;
 * @return {!Array<string>}
 */
proto.cloud_api.Simulator.prototype.getUserEmailsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setUserEmailsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.addUserEmails = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.clearUserEmailsList = function() {
  return this.setUserEmailsList([]);
};


/**
 * repeated RelatedUser users = 18;
 * @return {!Array<!proto.cloud_api.RelatedUser>}
 */
proto.cloud_api.Simulator.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.cloud_api.RelatedUser>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.RelatedUser, 18));
};


/**
 * @param {!Array<!proto.cloud_api.RelatedUser>} value
 * @return {!proto.cloud_api.Simulator} returns this
*/
proto.cloud_api.Simulator.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.cloud_api.RelatedUser=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.RelatedUser}
 */
proto.cloud_api.Simulator.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.cloud_api.RelatedUser, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string credentials = 17;
 * @return {string}
 */
proto.cloud_api.Simulator.prototype.getCredentials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Simulator} returns this
 */
proto.cloud_api.Simulator.prototype.setCredentials = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateSimulatorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateSimulatorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateSimulatorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
simulator: (f = msg.getSimulator()) && proto.cloud_api.Simulator.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateSimulatorRequest}
 */
proto.cloud_api.UpdateSimulatorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateSimulatorRequest;
  return proto.cloud_api.UpdateSimulatorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateSimulatorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateSimulatorRequest}
 */
proto.cloud_api.UpdateSimulatorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Simulator;
      reader.readMessage(value,proto.cloud_api.Simulator.deserializeBinaryFromReader);
      msg.setSimulator(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateSimulatorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateSimulatorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateSimulatorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimulator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.Simulator.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Simulator simulator = 1;
 * @return {?proto.cloud_api.Simulator}
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.getSimulator = function() {
  return /** @type{?proto.cloud_api.Simulator} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.Simulator, 1));
};


/**
 * @param {?proto.cloud_api.Simulator|undefined} value
 * @return {!proto.cloud_api.UpdateSimulatorRequest} returns this
*/
proto.cloud_api.UpdateSimulatorRequest.prototype.setSimulator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateSimulatorRequest} returns this
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.clearSimulator = function() {
  return this.setSimulator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.hasSimulator = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateSimulatorRequest} returns this
*/
proto.cloud_api.UpdateSimulatorRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateSimulatorRequest} returns this
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateSimulatorRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Simulators.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Simulators.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Simulators.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Simulators} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Simulators.toObject = function(includeInstance, msg) {
  var f, obj = {
simulatorsList: jspb.Message.toObjectList(msg.getSimulatorsList(),
    proto.cloud_api.Simulator.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Simulators}
 */
proto.cloud_api.Simulators.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Simulators;
  return proto.cloud_api.Simulators.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Simulators} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Simulators}
 */
proto.cloud_api.Simulators.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Simulator;
      reader.readMessage(value,proto.cloud_api.Simulator.deserializeBinaryFromReader);
      msg.addSimulators(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Simulators.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Simulators.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Simulators} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Simulators.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimulatorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.Simulator.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Simulator simulators = 1;
 * @return {!Array<!proto.cloud_api.Simulator>}
 */
proto.cloud_api.Simulators.prototype.getSimulatorsList = function() {
  return /** @type{!Array<!proto.cloud_api.Simulator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Simulator, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Simulator>} value
 * @return {!proto.cloud_api.Simulators} returns this
*/
proto.cloud_api.Simulators.prototype.setSimulatorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Simulator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Simulator}
 */
proto.cloud_api.Simulators.prototype.addSimulators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Simulator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Simulators} returns this
 */
proto.cloud_api.Simulators.prototype.clearSimulatorsList = function() {
  return this.setSimulatorsList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Simulators.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Simulators} returns this
*/
proto.cloud_api.Simulators.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Simulators} returns this
 */
proto.cloud_api.Simulators.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Simulators.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.cloud_api);
