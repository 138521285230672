import Cookie from "js-cookie";
import _ from "lodash";

import { CloudErrorCode } from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";

import { makeSendRequest, FetchError } from "../../common/http";
import endpoints from "./endpoints";
import {
  checkAPIStatus,
  checkStatus,
  isDeployedCloudApi,
  LOCAL_CLOUD_API_URL,
  parseResponse,
} from "./requests-common";

declare global {
  interface Window {
    // Needs to line up with the types from next-runtime-env
    __ENV: NodeJS.ProcessEnv;
    REACT_APP_BACKEND_URL: string;
  }
}

// NOTE(sam): flask-jwt-extended sends `access_token_cookie` and `refresh_token_cookie`, both of
// which are JWTs and are HttpOnly cookies so they aren't visible to client-side JavaScript. The
// fact that these cookies are automatically exchanged with the cloud api is not visibly captured
// in the logic in this file but is key to the authentication flow.
const CSRF_ACCESS_TOKEN_COOKIE = "csrf_access_token";
const CSRF_REFRESH_TOKEN_COOKIE = "csrf_refresh_token";
/**
 * Added for Streamlit. We want to create a python client for an authed cloud api user in a
 * Streamlit session without requiring the user to authenticate via login code each session.
 * So we store a refresh token in a cookie to persist it across sessions. Cloud api doesn't
 * use the cookie. It is managed in js only.
 */
const PY_CLIENT_REFRESH_TOKEN_COOKIE = "py_client_refresh_token";

// NOTE(sam): Theoretically we shouldn't have any logic in the api_util library that cares about
// environment variables so that it can be agnostic to the app it's being used in, but we need this
// for now while we still do data fetching from shared Redux actions defined in api_util (without
// doing a significant refactor to the Redux stuff to pipe through the cloud api url differently).
// For now we'll just keep it contained to this module.
const CLOUD_API_URL = (function () {
  if (typeof window !== "undefined") {
    // Check if the url is provided by next-runtime-env (this should the the case in customer portal)
    if (window.__ENV) {
      return window.__ENV.NEXT_PUBLIC_BACKEND_URL ?? LOCAL_CLOUD_API_URL;
    }
    return (
      // Check if it's defined globally by the config.js file (should be the case in prod admin portal)
      window.REACT_APP_BACKEND_URL ??
      // Check if available in CRA environment (admin portal dev)
      process.env.REACT_APP_BACKEND_URL ??
      // Logdash sets this one
      process.env.NEXT_PUBLIC_BACKEND_URL ??
      // Default to local cloud api if nothing specified
      LOCAL_CLOUD_API_URL
    );
  }
  // Server environment only applicable for NextJS
  return process.env.NEXT_PUBLIC_BACKEND_URL ?? LOCAL_CLOUD_API_URL;
})();

export const CLOUD_API_DEPLOYED = isDeployedCloudApi(CLOUD_API_URL);

export const getCookieDomain = () => {
  const hostname = window?.location?.hostname ?? "";
  // Share cookies only across skydio.com and skydio-dev.com subdomains.
  let domain = "";
  if (hostname.endsWith(".skydio.com") || hostname === "skydio.com") {
    domain = "skydio.com";
  } else if (hostname.endsWith(".skydio-dev.com") || hostname === "skydio-dev.com") {
    domain = "skydio-dev.com";
  } else if (hostname.endsWith(".skyd.io")) {
    domain = "skyd.io";
  } else if (hostname.endsWith("localhost")) {
    domain = "localhost";
  } else if (hostname.endsWith("skydio-vercel.com")) {
    domain = "skydio-vercel.com";
  }

  return domain;
};

const CSRF_COOKIE_DOMAIN = (function () {
  if (typeof window === "undefined") {
    return "";
  }

  return getCookieDomain();
})();

export const getPythonClientRefreshToken = () => {
  return Cookie.get(PY_CLIENT_REFRESH_TOKEN_COOKIE) || "";
};

export const setPythonClientRefreshToken = (token: string) => {
  return Cookie.set(PY_CLIENT_REFRESH_TOKEN_COOKIE, token, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
    secure: CLOUD_API_DEPLOYED,
    expires: 365,
  });
};

export function getCSRFAccessToken() {
  return Cookie.get(CSRF_ACCESS_TOKEN_COOKIE) || "";
}

function getCSRFRefreshToken() {
  return Cookie.get(CSRF_REFRESH_TOKEN_COOKIE) || "";
}

function setCSRFAccessToken(token: string) {
  return Cookie.set(CSRF_ACCESS_TOKEN_COOKIE, token, {
    // skydio.com allows access to all subdomains
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
    secure: CLOUD_API_DEPLOYED,
    expires: 365,
  });
}

function setCSRFRefreshToken(token: string) {
  return Cookie.set(CSRF_REFRESH_TOKEN_COOKIE, token, {
    // skydio.com allows access to all subdomains
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
    secure: CLOUD_API_DEPLOYED,
    expires: 365,
  });
}

export function logoutRemoveCookies() {
  Cookie.remove(CSRF_ACCESS_TOKEN_COOKIE, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
  });
  Cookie.remove(CSRF_REFRESH_TOKEN_COOKIE, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
  });
  Cookie.remove(PY_CLIENT_REFRESH_TOKEN_COOKIE, {
    domain: CLOUD_API_DEPLOYED ? CSRF_COOKIE_DOMAIN : undefined,
  });
}

function checkHeaders(response: Response) {
  if (response.headers.has("X-csrf_access_token")) {
    // remove the legacy cookie that was assigned to this subdomain only
    Cookie.remove(CSRF_ACCESS_TOKEN_COOKIE);

    setCSRFAccessToken(response.headers.get("X-csrf_access_token")!);
  }
  if (response.headers.has("X-csrf_refresh_token")) {
    // remove the legacy cookie that was assigned to this subdomain only
    Cookie.remove(CSRF_REFRESH_TOKEN_COOKIE);

    setCSRFRefreshToken(response.headers.get("X-csrf_refresh_token")!);
  }
  return response;
}

export async function fetcher(url: string, init: RequestInit = {}) {
  const response = await fetch(url, init);
  checkStatus(response);
  checkHeaders(response);
  const res = await parseResponse(response);
  return checkAPIStatus(res);
}

export async function graphqlFetcher(url: string, init: RequestInit = {}) {
  const response = await fetch(url, init);
  checkHeaders(response);
  const parsedResponse = await parseResponse(response);
  checkAPIStatus(parsedResponse, true);
  return parsedResponse;
}

// We only want one refresh request to happen at time globally, since each request will rewrite
// access_token_cookie & csrf_access_token. This attempts to fix an issue that
// resulted in a CSRF mismatch error when two refresh requests raced each other.
let isRefreshing = false; // a synchronous marker to check if the refreshPromise is pending
let refreshPromise = new Promise(() => {});
const waitOnRefresh = async () => {
  // Trigger an auth refresh or join any active refresh request
  if (!isRefreshing) {
    isRefreshing = true;
    refreshPromise = sendRequest(
      endpoints.REFRESH_AUTH,
      { clientKey: "web_js_client" },
      // refresh_token_cookie also gets included with this request automatically
      { headers: { "X-CSRF-TOKEN": getCSRFRefreshToken() } }
    ).finally(() => {
      isRefreshing = false;
    });
  }
  await refreshPromise;
};

export async function requestWithAuth(
  url: string,
  init: RequestInit = {},
  _fetcher: (url: string, init: RequestInit) => Promise<any> = fetcher
) {
  const authInit: RequestInit = {
    ...init,
    credentials: "include",
    headers: {
      // access_token_cookie also gets included with this request automatically
      "X-CSRF-TOKEN": getCSRFAccessToken(),
      ...init.headers,
    },
  };

  try {
    return await _fetcher(url, authInit);
  } catch (error) {
    if (error instanceof FetchError) {
      if (error.code && parseInt(error.code) === CloudErrorCode.Enum.REFRESH_REQUIRED) {
        // We've already attempted to refresh the token; if we're getting the same error again that
        // means the refresh token has also expired and the user needs to re-login
        if (url.endsWith(endpoints.REFRESH_AUTH.pathSpec as string)) {
          console.log("Refresh token expired.");
          throw error;
        }

        // refresh required with the api
        await waitOnRefresh();

        return await _fetcher(url, {
          ...authInit,
          headers: {
            ...authInit.headers,
            "X-CSRF-TOKEN": getCSRFAccessToken(),
          },
        });
      }
    }
    // if it's not a refresh error just throw it again
    console.log(error);
    throw error;
  }
}

const convertMixpanelKeys = (value: string, level: number) => {
  if (level === 1) {
    return value;
  }
  if (value.startsWith("$")) {
    return `$${_.camelCase(value)}`;
  }
  return _.camelCase(value);
};

export const sendRequest = makeSendRequest(requestWithAuth, CLOUD_API_URL);
export const sendMixpanelEventsRequest = makeSendRequest(
  requestWithAuth,
  CLOUD_API_URL,
  convertMixpanelKeys
);
export const sendRequestPreserveKeys = makeSendRequest(requestWithAuth, CLOUD_API_URL, false);
