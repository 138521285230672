import _ from "lodash";

type PlainObject = { [key: string]: any };
export type StringConverter = (value: string, level: number) => string;

function isPlainObject(value: any): value is PlainObject {
  return _.isPlainObject(value);
}

// The 'level' argument here allows your converter function to convert differently depending on
// the current recursive depth in a nested array/object
export function convertKeys(otherCase: any, converter: StringConverter, level = 0): any {
  if (_.isArray(otherCase)) {
    if (otherCase.length === 0) {
      return otherCase;
    }
    return otherCase.map((val: any) => convertKeys(val, converter, level + 1));
  }
  if (isPlainObject(otherCase)) {
    return _.reduce<PlainObject, PlainObject>(
      otherCase as PlainObject,
      (obj, val, key) => ({
        ...obj,
        [converter(key, level)]: convertKeys(val, converter, level + 1),
      }),
      {}
    );
  }
  return otherCase;
}

export const camelKeys = (otherCase: any) => convertKeys(otherCase, _.camelCase);
export const snakeKeys = (otherCase: any) => convertKeys(otherCase, _.snakeCase);
