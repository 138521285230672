// source: pbtypes/gen/scan_planner/radial_scan_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.scan_planner.RadialScanRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_planner.RadialScanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scan_planner.RadialScanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_planner.RadialScanRequest.displayName = 'proto.scan_planner.RadialScanRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_planner.RadialScanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_planner.RadialScanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_planner.RadialScanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.RadialScanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
gimbalPitch: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
numHeights: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
minDistanceToScanPlane: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
desiredRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
overlap: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
isLookupScan: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
shiftWaypointsWithTerrainData: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_planner.RadialScanRequest}
 */
proto.scan_planner.RadialScanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_planner.RadialScanRequest;
  return proto.scan_planner.RadialScanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_planner.RadialScanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_planner.RadialScanRequest}
 */
proto.scan_planner.RadialScanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGimbalPitch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumHeights(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinDistanceToScanPlane(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDesiredRange(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOverlap(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLookupScan(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShiftWaypointsWithTerrainData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_planner.RadialScanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_planner.RadialScanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_planner.RadialScanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.RadialScanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGimbalPitch();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getNumHeights();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMinDistanceToScanPlane();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDesiredRange();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getOverlap();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getIsLookupScan();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getShiftWaypointsWithTerrainData();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional double gimbal_pitch = 3;
 * @return {number}
 */
proto.scan_planner.RadialScanRequest.prototype.getGimbalPitch = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setGimbalPitch = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double num_heights = 4;
 * @return {number}
 */
proto.scan_planner.RadialScanRequest.prototype.getNumHeights = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setNumHeights = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double min_distance_to_scan_plane = 5;
 * @return {number}
 */
proto.scan_planner.RadialScanRequest.prototype.getMinDistanceToScanPlane = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setMinDistanceToScanPlane = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double desired_range = 6;
 * @return {number}
 */
proto.scan_planner.RadialScanRequest.prototype.getDesiredRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setDesiredRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double overlap = 7;
 * @return {number}
 */
proto.scan_planner.RadialScanRequest.prototype.getOverlap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setOverlap = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool is_lookup_scan = 8;
 * @return {boolean}
 */
proto.scan_planner.RadialScanRequest.prototype.getIsLookupScan = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setIsLookupScan = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool shift_waypoints_with_terrain_data = 9;
 * @return {boolean}
 */
proto.scan_planner.RadialScanRequest.prototype.getShiftWaypointsWithTerrainData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scan_planner.RadialScanRequest} returns this
 */
proto.scan_planner.RadialScanRequest.prototype.setShiftWaypointsWithTerrainData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


goog.object.extend(exports, proto.scan_planner);
