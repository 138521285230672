import { slice, findIndex } from "lodash";
import moment from "moment";

const timeUnits = [
  "years",
  "months",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds",
] as const;

type TimeUnit = (typeof timeUnits)[number];
type DurationComponents = Partial<Record<TimeUnit, number>>;

// Break down a microsecond duration into its constituent time components.
// Hopefully this should make it easier in the future to localize custom duration-related strings.
export const getDurationComponents = (
  microseconds: number,
  precision?: TimeUnit,
  maxUnit?: TimeUnit
) => {
  let totalDuration = moment.duration(microseconds / 1000);
  const components: DurationComponents = {};

  const unitsToIterate = slice(
    timeUnits,
    maxUnit ? findIndex(timeUnits, u => u === maxUnit) : 0,
    precision ? findIndex(timeUnits, u => u === precision) + 1 : timeUnits.length
  );

  return unitsToIterate.reduce((allComponents, unit) => {
    const unitQuantity = Math.floor(totalDuration.as(unit));
    if (unitQuantity >= 1) {
      allComponents[unit] = unitQuantity;
      totalDuration = totalDuration.subtract(unitQuantity, unit);
    }
    return allComponents;
  }, components);
};

export const formatMicroDuration = (
  microseconds: number,
  precision?: TimeUnit,
  maxUnit?: TimeUnit
) => {
  const components = getDurationComponents(microseconds, precision, maxUnit);

  return (
    timeUnits
      .filter(unit => unit in components)
      .map(unit => `${components[unit]} ${unit}`)
      .join(" ") || "0 minutes"
  );
};
