// source: pbtypes/gen/scan_output/scan_photo.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_body_trans_pb = require('../../../pbtypes/gen/body/trans_pb.js');
goog.object.extend(proto, pbtypes_gen_body_trans_pb);
var pbtypes_gen_scan_output_scan_photo_image_pb = require('../../../pbtypes/gen/scan_output/scan_photo_image_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_output_scan_photo_image_pb);
var pbtypes_gen_scan_planner_scan_photo_status_pb = require('../../../pbtypes/gen/scan_planner/scan_photo_status_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_scan_photo_status_pb);
var pbtypes_gen_scan_planner_scan_waypoint_pb = require('../../../pbtypes/gen/scan_planner/scan_waypoint_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_scan_waypoint_pb);
var pbtypes_gen_surface_scan_inspection_type_pb = require('../../../pbtypes/gen/surface_scan/inspection_type_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_inspection_type_pb);
var pbtypes_gen_user_camera_awb_mode_pb = require('../../../pbtypes/gen/user_camera/awb_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_awb_mode_pb);
var pbtypes_gen_user_camera_exposure_mode_pb = require('../../../pbtypes/gen/user_camera/exposure_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_exposure_mode_pb);
var pbtypes_gen_user_camera_recording_mode_pb = require('../../../pbtypes/gen/user_camera/recording_mode_pb.js');
goog.object.extend(proto, pbtypes_gen_user_camera_recording_mode_pb);
goog.exportSymbol('proto.scan_output.ScanPhoto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_output.ScanPhoto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scan_output.ScanPhoto.repeatedFields_, null);
};
goog.inherits(proto.scan_output.ScanPhoto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_output.ScanPhoto.displayName = 'proto.scan_output.ScanPhoto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scan_output.ScanPhoto.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_output.ScanPhoto.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_output.ScanPhoto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_output.ScanPhoto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_output.ScanPhoto.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
uclock: jspb.Message.getFieldWithDefault(msg, 2, 0),
globalMapTCamera: (f = msg.getGlobalMapTCamera()) && pbtypes_gen_body_trans_pb.TransPb.toObject(includeInstance, f),
nativeImagesList: jspb.Message.toObjectList(msg.getNativeImagesList(),
    pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.toObject, includeInstance),
undistortedImagesList: jspb.Message.toObjectList(msg.getUndistortedImagesList(),
    pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.toObject, includeInstance),
rangeImagesList: jspb.Message.toObjectList(msg.getRangeImagesList(),
    pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.toObject, includeInstance),
awbMode: jspb.Message.getFieldWithDefault(msg, 7, 0),
exposureMode: jspb.Message.getFieldWithDefault(msg, 8, 0),
recordingMode: jspb.Message.getFieldWithDefault(msg, 11, 0),
subjectMeanInvRange: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
waypoint: (f = msg.getWaypoint()) && pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint.toObject(includeInstance, f),
status: jspb.Message.getFieldWithDefault(msg, 13, 0),
inspectionType: jspb.Message.getFieldWithDefault(msg, 14, 0),
json: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_output.ScanPhoto}
 */
proto.scan_output.ScanPhoto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_output.ScanPhoto;
  return proto.scan_output.ScanPhoto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_output.ScanPhoto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_output.ScanPhoto}
 */
proto.scan_output.ScanPhoto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setUclock(value);
      break;
    case 3:
      var value = new pbtypes_gen_body_trans_pb.TransPb;
      reader.readMessage(value,pbtypes_gen_body_trans_pb.TransPb.deserializeBinaryFromReader);
      msg.setGlobalMapTCamera(value);
      break;
    case 4:
      var value = new pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage;
      reader.readMessage(value,pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.deserializeBinaryFromReader);
      msg.addNativeImages(value);
      break;
    case 5:
      var value = new pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage;
      reader.readMessage(value,pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.deserializeBinaryFromReader);
      msg.addUndistortedImages(value);
      break;
    case 6:
      var value = new pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage;
      reader.readMessage(value,pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.deserializeBinaryFromReader);
      msg.addRangeImages(value);
      break;
    case 7:
      var value = /** @type {!proto.user_camera.AwbMode.Enum} */ (reader.readEnum());
      msg.setAwbMode(value);
      break;
    case 8:
      var value = /** @type {!proto.user_camera.ExposureMode.Enum} */ (reader.readEnum());
      msg.setExposureMode(value);
      break;
    case 11:
      var value = /** @type {!proto.user_camera.RecordingMode.Enum} */ (reader.readEnum());
      msg.setRecordingMode(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSubjectMeanInvRange(value);
      break;
    case 12:
      var value = new pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint;
      reader.readMessage(value,pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint.deserializeBinaryFromReader);
      msg.setWaypoint(value);
      break;
    case 13:
      var value = /** @type {!proto.scan_planner.ScanPhotoStatus.Enum} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {!proto.surface_scan.InspectionType.Enum} */ (reader.readEnum());
      msg.setInspectionType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_output.ScanPhoto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_output.ScanPhoto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_output.ScanPhoto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_output.ScanPhoto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = message.getUclock();
  if (f !== 0) {
    writer.writeSint64(
      2,
      f
    );
  }
  f = message.getGlobalMapTCamera();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_body_trans_pb.TransPb.serializeBinaryToWriter
    );
  }
  f = message.getNativeImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.serializeBinaryToWriter
    );
  }
  f = message.getUndistortedImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.serializeBinaryToWriter
    );
  }
  f = message.getRangeImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage.serializeBinaryToWriter
    );
  }
  f = message.getAwbMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getExposureMode();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getRecordingMode();
  if (f !== 0.0) {
    writer.writeEnum(
      11,
      f
    );
  }
  f = message.getSubjectMeanInvRange();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getWaypoint();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getInspectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional sint64 utime = 1;
 * @return {number}
 */
proto.scan_output.ScanPhoto.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional sint64 uclock = 2;
 * @return {number}
 */
proto.scan_output.ScanPhoto.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional body.TransPb global_map_t_camera = 3;
 * @return {?proto.body.TransPb}
 */
proto.scan_output.ScanPhoto.prototype.getGlobalMapTCamera = function() {
  return /** @type{?proto.body.TransPb} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_body_trans_pb.TransPb, 3));
};


/**
 * @param {?proto.body.TransPb|undefined} value
 * @return {!proto.scan_output.ScanPhoto} returns this
*/
proto.scan_output.ScanPhoto.prototype.setGlobalMapTCamera = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.clearGlobalMapTCamera = function() {
  return this.setGlobalMapTCamera(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanPhoto.prototype.hasGlobalMapTCamera = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ScanPhotoImage native_images = 4;
 * @return {!Array<!proto.scan_output.ScanPhotoImage>}
 */
proto.scan_output.ScanPhoto.prototype.getNativeImagesList = function() {
  return /** @type{!Array<!proto.scan_output.ScanPhotoImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage, 4));
};


/**
 * @param {!Array<!proto.scan_output.ScanPhotoImage>} value
 * @return {!proto.scan_output.ScanPhoto} returns this
*/
proto.scan_output.ScanPhoto.prototype.setNativeImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.scan_output.ScanPhotoImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scan_output.ScanPhotoImage}
 */
proto.scan_output.ScanPhoto.prototype.addNativeImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.scan_output.ScanPhotoImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.clearNativeImagesList = function() {
  return this.setNativeImagesList([]);
};


/**
 * repeated ScanPhotoImage undistorted_images = 5;
 * @return {!Array<!proto.scan_output.ScanPhotoImage>}
 */
proto.scan_output.ScanPhoto.prototype.getUndistortedImagesList = function() {
  return /** @type{!Array<!proto.scan_output.ScanPhotoImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage, 5));
};


/**
 * @param {!Array<!proto.scan_output.ScanPhotoImage>} value
 * @return {!proto.scan_output.ScanPhoto} returns this
*/
proto.scan_output.ScanPhoto.prototype.setUndistortedImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.scan_output.ScanPhotoImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scan_output.ScanPhotoImage}
 */
proto.scan_output.ScanPhoto.prototype.addUndistortedImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.scan_output.ScanPhotoImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.clearUndistortedImagesList = function() {
  return this.setUndistortedImagesList([]);
};


/**
 * repeated ScanPhotoImage range_images = 6;
 * @return {!Array<!proto.scan_output.ScanPhotoImage>}
 */
proto.scan_output.ScanPhoto.prototype.getRangeImagesList = function() {
  return /** @type{!Array<!proto.scan_output.ScanPhotoImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_gen_scan_output_scan_photo_image_pb.ScanPhotoImage, 6));
};


/**
 * @param {!Array<!proto.scan_output.ScanPhotoImage>} value
 * @return {!proto.scan_output.ScanPhoto} returns this
*/
proto.scan_output.ScanPhoto.prototype.setRangeImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.scan_output.ScanPhotoImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scan_output.ScanPhotoImage}
 */
proto.scan_output.ScanPhoto.prototype.addRangeImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.scan_output.ScanPhotoImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.clearRangeImagesList = function() {
  return this.setRangeImagesList([]);
};


/**
 * optional user_camera.AwbMode.Enum awb_mode = 7;
 * @return {!proto.user_camera.AwbMode.Enum}
 */
proto.scan_output.ScanPhoto.prototype.getAwbMode = function() {
  return /** @type {!proto.user_camera.AwbMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.user_camera.AwbMode.Enum} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setAwbMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional user_camera.ExposureMode.Enum exposure_mode = 8;
 * @return {!proto.user_camera.ExposureMode.Enum}
 */
proto.scan_output.ScanPhoto.prototype.getExposureMode = function() {
  return /** @type {!proto.user_camera.ExposureMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.user_camera.ExposureMode.Enum} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setExposureMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional user_camera.RecordingMode.Enum recording_mode = 11;
 * @return {!proto.user_camera.RecordingMode.Enum}
 */
proto.scan_output.ScanPhoto.prototype.getRecordingMode = function() {
  return /** @type {!proto.user_camera.RecordingMode.Enum} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {!proto.user_camera.RecordingMode.Enum} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setRecordingMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};


/**
 * optional float subject_mean_inv_range = 10;
 * @return {number}
 */
proto.scan_output.ScanPhoto.prototype.getSubjectMeanInvRange = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setSubjectMeanInvRange = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional scan_planner.ScanWaypoint waypoint = 12;
 * @return {?proto.scan_planner.ScanWaypoint}
 */
proto.scan_output.ScanPhoto.prototype.getWaypoint = function() {
  return /** @type{?proto.scan_planner.ScanWaypoint} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_scan_waypoint_pb.ScanWaypoint, 12));
};


/**
 * @param {?proto.scan_planner.ScanWaypoint|undefined} value
 * @return {!proto.scan_output.ScanPhoto} returns this
*/
proto.scan_output.ScanPhoto.prototype.setWaypoint = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.clearWaypoint = function() {
  return this.setWaypoint(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_output.ScanPhoto.prototype.hasWaypoint = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional scan_planner.ScanPhotoStatus.Enum status = 13;
 * @return {!proto.scan_planner.ScanPhotoStatus.Enum}
 */
proto.scan_output.ScanPhoto.prototype.getStatus = function() {
  return /** @type {!proto.scan_planner.ScanPhotoStatus.Enum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.scan_planner.ScanPhotoStatus.Enum} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional surface_scan.InspectionType.Enum inspection_type = 14;
 * @return {!proto.surface_scan.InspectionType.Enum}
 */
proto.scan_output.ScanPhoto.prototype.getInspectionType = function() {
  return /** @type {!proto.surface_scan.InspectionType.Enum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.surface_scan.InspectionType.Enum} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setInspectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string json = 9;
 * @return {string}
 */
proto.scan_output.ScanPhoto.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.scan_output.ScanPhoto} returns this
 */
proto.scan_output.ScanPhoto.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.scan_output);
