import _ from "lodash";
import struct_pb from "google-protobuf/google/protobuf/struct_pb";

import api_token_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/api_token_pb";
import auth_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/auth_pb";
import cloud_config_pb from "@skydio/pbtypes/pbtypes/tools/cloud_config/cloud_config_pb";
import feature_flags_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/feature_flags_pb";
import files_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/files_pb";
import flight_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/flight_pb";
import kinesis_webrtc_pb from "@skydio/pbtypes/pbtypes/infrastructure/kinesis_webrtc/kinesis_webrtc_pb";
import organization_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/organization_pb";
import release_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/release_pb";
import scan_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/scan_pb";
import simulator_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/simulator_pb";
import ucon_pb from "@skydio/pbtypes/pbtypes/vehicle/infrastructure/flight_deck/ucon_pb";
import user_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/user_pb";
import vehicle_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/vehicle_pb";
import wifi_setting_pb from "@skydio/pbtypes/pbtypes/tools/cloud_api/wifi_setting_pb";

import { createRoute, createProtobufRoute } from "../../common/http";

import {
  AuthenticateRequest,
  AuthRefreshRequest,
  LoginRequest,
  LoginTypeRequest,
  LoginTypeResponse,
  TokenExchangeRequest,
} from "./auth/types";
import { CloudConfigRequest, CloudConfigVehicleRequest } from "./cloud_config/types";
import {
  DataFileRequest,
  DataFileResponse,
  DataFilesRequest,
  DownloadDataFilesRequest,
} from "./data_files/types";
import { FeatureFlagRequest } from "./feature_flags/types";
import { FlightsRequest, FlightRequest, FlightStatsRequest } from "./flights/types";
import { GroupsResponse } from "./groups/types";
import {
  ReleasesRequest,
  ReleaseRequest,
  ReleaseFilesRequest,
  UpdateReleaseRequest,
  SetReleaseOverrideRequest,
  SetDeviceReleaseOverrideRequest,
  DownloadReleaseRequest,
} from "./releases/types";
import { DevReleaseFilesRequest, DevReleaseFileRequest } from "./dev_releases/types";
import {
  ControllerReleasesProtoRequest,
  ControllerReleaseRequest,
  UpdateControllerReleaseRequest,
  DownloadControllerReleaseRequest,
  SetControllerReleaseOverrideRequest,
  DeleteControllerReleaseOverrideRequest,
} from "./controller_releases/types";
import {
  SimulatorsRequest,
  SimulatorRequest,
  AddOrUpdateSimulatorRequest,
} from "./simulators/types";
import { UsersRequest, UserRequest, AddOrUpdateUserRequest } from "./users/types";
import { VehiclesRequest, VehicleRequest, UpdateVehicleRequest } from "./vehicles/types";
import {
  VehicleEventsRequest,
  VehicleEventsResponse,
  VehicleFaultsResponse,
  VehicleErrorReportsResponse,
  MobileEventsRequest,
  MobileEventsResponse,
  OrganizationRequest,
} from "./analytics/types";
import {
  UpdateOrganizationRequest,
  DeleteOrganizationRequest,
  InviteUserRequest,
  AcceptInviteRequest,
  RemoveUserRequest,
  GetOrganizationStatsRequest,
  UploadLogoRequest,
} from "./organizations/types";
import { UpdateOrgUCONKindConfigRequest } from "./ucon_upload_config/types";
import { WebRTCChannelRequest } from "./webrtc/types";
import {
  GetWifiSettingsRequest,
  DeleteWifiSettingRequest,
  CreateWifiSettingRequest,
  UpdateWifiSettingRequest,
} from "./wifi_settings/types";
import { GetScanRequest } from "./scans/types";

// All API endpoints we request from are described here
const cloudApiEndpoints = {
  LOGIN: createRoute<LoginRequest, void>("auth/login", "POST"),
  LOGIN_TYPES: createRoute<LoginTypeRequest, LoginTypeResponse>("auth/login_type", "POST"),
  AUTHENTICATE: createRoute<AuthenticateRequest, void>("cauth/authenticate", "POST"),
  // We want complete tokens rather than CSRF tokens, for apps like Streamlit that
  // make python cloud api clients w/ user credentials.
  AUTHENTICATE_NO_COOKIES: createRoute<AuthenticateRequest, auth_pb.AuthenticateResponse.AsObject>(
    "auth/authenticate",
    "POST"
  ),
  LOGOUT: createRoute<undefined, void>("cauth/logout", "POST"),
  REFRESH_AUTH: createRoute<AuthRefreshRequest, void>("cauth/refresh", "POST"),
  REQUEST_JWT: createProtobufRoute<TokenExchangeRequest, auth_pb.TokenExchangeResponse>(
    "auth/request_jwt",
    "POST",
    auth_pb.TokenExchangeResponse.deserializeBinary
  ),
  GET_FLIGHTS: createProtobufRoute<FlightsRequest, flight_pb.Flights>(
    "flights",
    "GET",
    flight_pb.Flights.deserializeBinary
  ),
  GET_FLIGHT: createProtobufRoute<FlightRequest, flight_pb.Flight>(
    ({ flightId }) => `flight/${flightId}`,
    "GET",
    flight_pb.Flight.deserializeBinary
  ),
  GET_FLIGHT_STATS: createProtobufRoute<FlightStatsRequest, flight_pb.FlightStats>(
    "flight_stats",
    "GET",
    flight_pb.FlightStats.deserializeBinary
  ),
  DOWNLOAD_FLIGHT_CSV: createRoute<FlightRequest, Response>(
    ({ flightId }) => `flight/${flightId}/telemetry/csv`,
    "GET"
  ),
  DOWNLOAD_FLIGHTS_CSV: createRoute<FlightsRequest, Response>("flights/download", "GET"),
  GET_FLIGHT_DATA_FILE: createRoute<DataFileRequest, DataFileResponse>(
    ({ uuid }) => `flight_data/${uuid}`,
    "GET"
  ),
  DOWNLOAD_FLIGHT_DATA_FILE: createRoute<DataFileRequest, Response>(
    ({ uuid }) => `flight_data/${uuid}/download`,
    "GET"
  ),
  GET_FLIGHT_DATA_FILES: createProtobufRoute<DataFilesRequest, files_pb.DataFiles>(
    "flight_data_files",
    "GET",
    files_pb.DataFiles.deserializeBinary
  ),
  DOWNLOAD_FLIGHT_FILES_ZIP: createRoute<DownloadDataFilesRequest, Response>(
    "flight_data_files/download",
    "POST"
  ),
  GET_FILE_KINDS: createProtobufRoute<undefined, files_pb.GetFileKindsResponse>(
    "file_kinds",
    "GET",
    files_pb.GetFileKindsResponse.deserializeBinary
  ),
  GET_GROUPS: createRoute<undefined, GroupsResponse>("groups", "GET"),
  GET_RELEASES: createProtobufRoute<ReleasesRequest, release_pb.Releases>(
    "releases",
    "GET",
    release_pb.Releases.deserializeBinary
  ),
  GET_RELEASE: createProtobufRoute<ReleaseRequest, release_pb.ReleaseFile>(
    ({ key }) => `release/${key}`,
    "GET",
    release_pb.ReleaseFile.deserializeBinary
  ),
  GET_RELEASE_FILES: createProtobufRoute<
    ReleaseFilesRequest | DevReleaseFilesRequest,
    release_pb.ReleaseFiles
  >("release_files", "GET", release_pb.ReleaseFiles.deserializeBinary),
  GET_RELEASE_FILE: createProtobufRoute<DevReleaseFileRequest, release_pb.ReleaseFile>(
    ({ key }) => `release_file/${key}`,
    "GET",
    release_pb.ReleaseFile.deserializeBinary
  ),
  GET_CONTROLLER_RELEASES: createProtobufRoute<
    ControllerReleasesProtoRequest,
    release_pb.ControllerReleases
  >("controller_releases", "POST", release_pb.ControllerReleases.deserializeBinary),
  GET_CONTROLLER_RELEASE: createProtobufRoute<
    ControllerReleaseRequest,
    release_pb.ControllerReleaseFile
  >(
    ({ key }) => `controller_release/${key}`,
    "GET",
    release_pb.ControllerReleaseFile.deserializeBinary
  ),
  UPDATE_RELEASE: createProtobufRoute<UpdateReleaseRequest, release_pb.ReleaseFile>(
    ({ key }) => `release/${key}`,
    "POST",
    release_pb.ReleaseFile.deserializeBinary
  ),
  DOWNLOAD_RELEASE: createProtobufRoute<DownloadReleaseRequest, release_pb.FindReleaseResponse>(
    ({ key }) => `release/get/${key}`,
    "POST",
    release_pb.FindReleaseResponse.deserializeBinary
  ),
  UPDATE_CONTROLLER_RELEASE: createProtobufRoute<
    UpdateControllerReleaseRequest,
    release_pb.UpdateControllerReleaseResponse
  >(
    ({ key }) => `controller_release/${key}`,
    "POST",
    release_pb.UpdateControllerReleaseResponse.deserializeBinary
  ),
  DOWNLOAD_CONTROLLER_RELEASE: createProtobufRoute<
    DownloadControllerReleaseRequest,
    release_pb.FindControllerReleaseResponse
  >("controller_release/get", "POST", release_pb.FindControllerReleaseResponse.deserializeBinary),
  SET_USER_RELEASE_OVERRIDE: createProtobufRoute<
    SetReleaseOverrideRequest,
    release_pb.UserReleaseOverrides
  >(
    ({ key }) => `/release/set_user/${key}`,
    "POST",
    release_pb.UserReleaseOverrides.deserializeBinary
  ),
  SET_DEVICE_RELEASE_OVERRIDE: createProtobufRoute<
    SetDeviceReleaseOverrideRequest,
    release_pb.DeviceReleaseOverrides
  >(
    ({ key }) => `/release/set_device/${key}`,
    "POST",
    release_pb.DeviceReleaseOverrides.deserializeBinary
  ),
  SET_CONTROLLER_RELEASE_OVERRIDE: createProtobufRoute<
    SetControllerReleaseOverrideRequest,
    release_pb.ControllerReleaseOverrides
  >(
    ({ key }) => `/controller_release/override/${key}`,
    "POST",
    release_pb.ControllerReleaseOverrides.deserializeBinary
  ),
  DELETE_CONTROLLER_RELEASE_OVERRIDE: createProtobufRoute<
    DeleteControllerReleaseOverrideRequest,
    release_pb.ControllerReleaseOverrides
  >(
    ({ key }) => `/controller_release/override/${key}`,
    "DELETE",
    release_pb.ControllerReleaseOverrides.deserializeBinary
  ),
  GET_SIMULATORS: createProtobufRoute<SimulatorsRequest, simulator_pb.Simulators>(
    "simulators",
    "GET",
    simulator_pb.Simulators.deserializeBinary
  ),
  GET_SIMULATOR: createProtobufRoute<SimulatorRequest, simulator_pb.Simulator>(
    ({ uuid }) => `simulator/${uuid}`,
    "GET",
    simulator_pb.Simulator.deserializeBinary
  ),
  ADD_SIMULATOR: createProtobufRoute<AddOrUpdateSimulatorRequest, simulator_pb.Simulator>(
    "simulator",
    "POST",
    simulator_pb.Simulator.deserializeBinary
  ),
  UPDATE_SIMULATOR: createProtobufRoute<AddOrUpdateSimulatorRequest, simulator_pb.Simulator>(
    ({ uuid }) => `simulator/${uuid}`,
    "POST",
    simulator_pb.Simulator.deserializeBinary
  ),
  GET_CURRENT_USER: createProtobufRoute<undefined, user_pb.User>(
    "user",
    "GET",
    user_pb.User.deserializeBinary
  ),
  GET_USERS: createProtobufRoute<UsersRequest, user_pb.Users>(
    "users",
    "GET",
    user_pb.Users.deserializeBinary
  ),
  GET_USER: createProtobufRoute<UserRequest, user_pb.User>(
    ({ identifier }) => `user/${identifier}`,
    "GET",
    user_pb.User.deserializeBinary
  ),
  ADD_USER: createProtobufRoute<AddOrUpdateUserRequest, user_pb.User>(
    ({ identifier }) => `user/${identifier}`,
    "POST",
    user_pb.User.deserializeBinary
  ),
  UPDATE_USER: createProtobufRoute<AddOrUpdateUserRequest, user_pb.User>(
    ({ identifier }) => `user/${identifier}`,
    "POST",
    user_pb.User.deserializeBinary
  ),
  GET_VEHICLES: createProtobufRoute<VehiclesRequest, vehicle_pb.Vehicles>(
    "vehicles",
    "GET",
    vehicle_pb.Vehicles.deserializeBinary
  ),
  GET_DEVICE_DATA_FILE: createRoute<DataFileRequest, DataFileResponse>(
    ({ uuid }) => `vehicle_data/${uuid}`,
    "GET"
  ),
  GET_DEVICE_DATA_FILES: createProtobufRoute<DataFilesRequest, files_pb.DataFiles>(
    "vehicle_data_files",
    "GET",
    files_pb.DataFiles.deserializeBinary
  ),
  GET_VEHICLE: createProtobufRoute<VehicleRequest, vehicle_pb.Vehicle>(
    ({ vehicleId }) => `vehicle/${vehicleId}`,
    "GET",
    vehicle_pb.Vehicle.deserializeBinary
  ),
  UPDATE_VEHICLE: createProtobufRoute<UpdateVehicleRequest, vehicle_pb.Vehicle>(
    ({ vehicleId }) => `vehicle/${vehicleId}`,
    "POST",
    vehicle_pb.Vehicle.deserializeBinary
  ),
  GET_VEHICLE_EVENTS: createRoute<VehicleEventsRequest, VehicleEventsResponse>(
    ({ vehicleId }) => `mixpanel/vehicle/${vehicleId}`,
    "GET"
  ),
  GET_VEHICLE_FAULTS: createRoute<VehicleEventsRequest, VehicleFaultsResponse>(
    ({ vehicleId }) => `mixpanel/vehicle/${vehicleId}/faults`,
    "GET"
  ),
  GET_VEHICLE_ERROR_REPORTS: createRoute<VehicleEventsRequest, VehicleErrorReportsResponse>(
    ({ vehicleId }) => `mixpanel/vehicle/${vehicleId}/error_reports`,
    "GET"
  ),
  GET_MOBILE_EVENTS: createRoute<MobileEventsRequest, MobileEventsResponse>(
    ({ email }) => `mixpanel/mobile/${email}`,
    "GET"
  ),
  GET_ORGANIZATION: createProtobufRoute<OrganizationRequest, organization_pb.Organization>(
    ({ uuid }) => `organization/${uuid}`,
    "GET",
    organization_pb.Organization.deserializeBinary
  ),
  GET_ORGANIZATIONS: createProtobufRoute<undefined, organization_pb.Organizations>(
    "organizations",
    "GET",
    organization_pb.Organizations.deserializeBinary
  ),
  UPDATE_ORGANIZATION: createProtobufRoute<UpdateOrganizationRequest, organization_pb.Organization>(
    ({ uuid }) => `organization/${uuid}`,
    "POST",
    organization_pb.Organization.deserializeBinary
  ),
  ADD_ORGANIZATION: createProtobufRoute<UpdateOrganizationRequest, organization_pb.Organization>(
    "organization",
    "POST",
    organization_pb.Organization.deserializeBinary
  ),
  DELETE_ORGANIZATION: createProtobufRoute<DeleteOrganizationRequest, struct_pb.Struct>(
    ({ uuid }) => `organization/${uuid}/delete`,
    "POST",
    struct_pb.Struct.deserializeBinary
  ),
  GET_ORG_STATS: createProtobufRoute<undefined, organization_pb.OrganizationStats>(
    ({ uuid }) => `organization/${uuid}/stats`,
    "GET",
    organization_pb.OrganizationStats.deserializeBinary
  ),
  GET_ORG_UCON_UPLOAD_CONFIG: createProtobufRoute<undefined, ucon_pb.UploadConfig>(
    ({ uuid }) => `organization/${uuid}/ucon_config`,
    "GET",
    ucon_pb.UploadConfig.deserializeBinary
  ),
  UPDATE_ORG_UCON_UPLOAD_CONFIG: createProtobufRoute<
    UpdateOrgUCONKindConfigRequest,
    ucon_pb.UploadConfig
  >(
    ({ uuid }) => `organization/${uuid}/update_kind`,
    "POST",
    ucon_pb.UploadConfig.deserializeBinary
  ),
  REMOVE_ORG_UCON_UPLOAD_CONFIG: createProtobufRoute<
    UpdateOrgUCONKindConfigRequest,
    ucon_pb.UploadConfig
  >(
    ({ uuid }) => `organization/${uuid}/remove_kind`,
    "POST",
    ucon_pb.UploadConfig.deserializeBinary
  ),
  INVITE_USER_TO_ORG: createProtobufRoute<
    InviteUserRequest,
    organization_pb.InviteUserToOrganizationResponse
  >(
    ({ uuid, email }) => `organization/${uuid}/invite/${email}`,
    "POST",
    organization_pb.InviteUserToOrganizationResponse.deserializeBinary
  ),
  REMOVE_USER_FROM_ORG: createRoute<RemoveUserRequest, void>(
    ({ uuid, email }) => `organization/${uuid}/remove/${email}`,
    "POST"
  ),
  ACCEPT_ORG_INVITE: createRoute<AcceptInviteRequest, void>(
    ({ jwtPayload }) => `organization/accept_invite/${jwtPayload}`,
    "GET"
  ),
  GET_FEATURE_FLAGS: createProtobufRoute<undefined, feature_flags_pb.FeatureFlags>(
    "feature_flags",
    "GET",
    feature_flags_pb.FeatureFlags.deserializeBinary
  ),
  GET_FEATURE_FLAGS_FOR_ORG_ROLE: createProtobufRoute<
    FeatureFlagRequest,
    feature_flags_pb.FeatureFlags
  >("feature_flags", "POST", feature_flags_pb.FeatureFlags.deserializeBinary),
  GET_KINESIS_CHANNEL: createProtobufRoute<
    WebRTCChannelRequest,
    kinesis_webrtc_pb.KinesisCredentials
  >(
    ({ channelName }) => `kinesis/${channelName}`,
    "GET",
    kinesis_webrtc_pb.KinesisCredentials.deserializeBinary
  ),
  GET_CLOUD_CONFIG: createProtobufRoute<CloudConfigRequest, cloud_config_pb.CloudConfig>(
    "cloud_config",
    "POST",
    cloud_config_pb.CloudConfig.deserializeBinary
  ),
  GET_VEHICLE_CLOUD_CONFIG: createProtobufRoute<
    CloudConfigVehicleRequest,
    cloud_config_pb.CloudConfig
  >(
    ({ vehicleId }) => `cloud_config/vehicle/${vehicleId}`,
    "POST",
    cloud_config_pb.CloudConfig.deserializeBinary
  ),

  // SETTINGS: WIFI SETTINGS
  GET_WIFI_SETTINGS: createProtobufRoute<GetWifiSettingsRequest, wifi_setting_pb.WifiSettings>(
    ({ organizationId }) => `${organizationId}/wifi_settings`,
    "GET",
    wifi_setting_pb.WifiSettings.deserializeBinary
  ),
  CREATE_WIFI_SETTING: createProtobufRoute<CreateWifiSettingRequest, wifi_setting_pb.WifiSetting>(
    ({ organizationId }) => `${organizationId}/wifi_settings`,
    "POST",
    wifi_setting_pb.WifiSetting.deserializeBinary
  ),
  UPDATE_WIFI_SETTING: createProtobufRoute<UpdateWifiSettingRequest, wifi_setting_pb.WifiSetting>(
    ({ organizationId, wifiSettingId }) => `${organizationId}/wifi_setting/${wifiSettingId}`,
    "POST",
    wifi_setting_pb.WifiSetting.deserializeBinary
  ),
  DELETE_WIFI_SETTING: createProtobufRoute<DeleteWifiSettingRequest, wifi_setting_pb.WifiSetting>(
    ({ organizationId, wifiSettingId }) => `${organizationId}/wifi_setting/${wifiSettingId}`,
    "DELETE",
    wifi_setting_pb.WifiSetting.deserializeBinary
  ),

  // DOWNLOAD CSV FILES
  DOWNLOAD_STATS_CSV: createRoute<undefined, Response>(
    ({ organizationId }) => `organization/${organizationId}/download_stats`,
    "GET"
  ),

  // AGGREGATES OVER TIME: PILOTS, STORAGE
  GET_ORG_PILOT_STATS: createProtobufRoute<
    GetOrganizationStatsRequest,
    organization_pb.OrganizationPilotStats
  >(
    ({ organizationId }) => `organization/${organizationId}/pilot_stats`,
    "GET",
    organization_pb.OrganizationPilotStats.deserializeBinary
  ),

  GET_ORG_STORAGE_STATS: createProtobufRoute<
    GetOrganizationStatsRequest,
    organization_pb.StorageStats
  >(
    ({ organizationId }) => `organization/${organizationId}/storage_stats`,
    "GET",
    organization_pb.StorageStats.deserializeBinary
  ),

  UPLOAD_LOGO: createProtobufRoute<UploadLogoRequest, struct_pb.Struct>(
    ({ organizationId }) => `organization/${organizationId}/logo`,
    "POST",
    struct_pb.Struct.deserializeBinary
  ),

  DOWNLOAD_ORGANIZATION_FILE: createRoute<DataFileRequest, Response>(
    ({ uuid }) => `organization_file/${uuid}`,
    "GET"
  ),

  DOWNLOAD_THUMBNAIL: createRoute<DataFileRequest, Response>(
    ({ uuid }) => `flight_data/${uuid}/thumbnail`,
    "GET"
  ),

  GET_SCAN: createProtobufRoute<GetScanRequest, scan_pb.ScanPb>(
    ({ uuid }) => `scan/${uuid}`,
    "GET",
    scan_pb.ScanPb.deserializeBinary
  ),
};

export default cloudApiEndpoints;
