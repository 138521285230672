// source: pbtypes/infrastructure/kinesis_webrtc/kinesis_webrtc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var pbtypes_gen_cloud_config_client_type_pb = require('../../../pbtypes/gen/cloud_config/client_type_pb.js');
goog.object.extend(proto, pbtypes_gen_cloud_config_client_type_pb);
goog.exportSymbol('proto.kinesis_webrtc.AWSIAMCredentials', null, global);
goog.exportSymbol('proto.kinesis_webrtc.ConnectedClient', null, global);
goog.exportSymbol('proto.kinesis_webrtc.KinesisCredentials', null, global);
goog.exportSymbol('proto.kinesis_webrtc.KinesisCredentialsResponse', null, global);
goog.exportSymbol('proto.kinesis_webrtc.KinesisSignalingChannel', null, global);
goog.exportSymbol('proto.kinesis_webrtc.PeerConnectionStatus', null, global);
goog.exportSymbol('proto.kinesis_webrtc.SignalingStatus', null, global);
goog.exportSymbol('proto.kinesis_webrtc.StopKinesis', null, global);
goog.exportSymbol('proto.kinesis_webrtc.WebrtcClientStatus', null, global);
goog.exportSymbol('proto.kinesis_webrtc.WebrtcMasterStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.AWSIAMCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.AWSIAMCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.AWSIAMCredentials.displayName = 'proto.kinesis_webrtc.AWSIAMCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.KinesisSignalingChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.KinesisSignalingChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.KinesisSignalingChannel.displayName = 'proto.kinesis_webrtc.KinesisSignalingChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.KinesisCredentials = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.KinesisCredentials, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.KinesisCredentials.displayName = 'proto.kinesis_webrtc.KinesisCredentials';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.KinesisCredentialsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.KinesisCredentialsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.KinesisCredentialsResponse.displayName = 'proto.kinesis_webrtc.KinesisCredentialsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.WebrtcMasterStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.kinesis_webrtc.WebrtcMasterStatus.repeatedFields_, null);
};
goog.inherits(proto.kinesis_webrtc.WebrtcMasterStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.WebrtcMasterStatus.displayName = 'proto.kinesis_webrtc.WebrtcMasterStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.ConnectedClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.ConnectedClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.ConnectedClient.displayName = 'proto.kinesis_webrtc.ConnectedClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.StopKinesis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.StopKinesis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.StopKinesis.displayName = 'proto.kinesis_webrtc.StopKinesis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.kinesis_webrtc.WebrtcClientStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.kinesis_webrtc.WebrtcClientStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.kinesis_webrtc.WebrtcClientStatus.displayName = 'proto.kinesis_webrtc.WebrtcClientStatus';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.AWSIAMCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.AWSIAMCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.AWSIAMCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
secretAccessKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
accessKeyId: jspb.Message.getFieldWithDefault(msg, 2, ""),
expiration: (f = msg.getExpiration()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
sessionToken: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials}
 */
proto.kinesis_webrtc.AWSIAMCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.AWSIAMCredentials;
  return proto.kinesis_webrtc.AWSIAMCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.AWSIAMCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials}
 */
proto.kinesis_webrtc.AWSIAMCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretAccessKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessKeyId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiration(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.AWSIAMCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.AWSIAMCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.AWSIAMCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecretAccessKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKeyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpiration();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSessionToken();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string secret_access_key = 1;
 * @return {string}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.getSecretAccessKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials} returns this
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.setSecretAccessKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_key_id = 2;
 * @return {string}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.getAccessKeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials} returns this
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.setAccessKeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp expiration = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.getExpiration = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials} returns this
*/
proto.kinesis_webrtc.AWSIAMCredentials.prototype.setExpiration = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials} returns this
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.clearExpiration = function() {
  return this.setExpiration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.hasExpiration = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string session_token = 4;
 * @return {string}
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.getSessionToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.AWSIAMCredentials} returns this
 */
proto.kinesis_webrtc.AWSIAMCredentials.prototype.setSessionToken = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.KinesisSignalingChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.KinesisSignalingChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.KinesisSignalingChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.KinesisSignalingChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
channelArn: jspb.Message.getFieldWithDefault(msg, 1, ""),
channelName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.KinesisSignalingChannel}
 */
proto.kinesis_webrtc.KinesisSignalingChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.KinesisSignalingChannel;
  return proto.kinesis_webrtc.KinesisSignalingChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.KinesisSignalingChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.KinesisSignalingChannel}
 */
proto.kinesis_webrtc.KinesisSignalingChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelArn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannelName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.KinesisSignalingChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.KinesisSignalingChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.KinesisSignalingChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.KinesisSignalingChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelArn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string channel_arn = 1;
 * @return {string}
 */
proto.kinesis_webrtc.KinesisSignalingChannel.prototype.getChannelArn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.KinesisSignalingChannel} returns this
 */
proto.kinesis_webrtc.KinesisSignalingChannel.prototype.setChannelArn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string channel_name = 2;
 * @return {string}
 */
proto.kinesis_webrtc.KinesisSignalingChannel.prototype.getChannelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.KinesisSignalingChannel} returns this
 */
proto.kinesis_webrtc.KinesisSignalingChannel.prototype.setChannelName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.KinesisCredentials.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.KinesisCredentials} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.KinesisCredentials.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 3, 0),
credentials: (f = msg.getCredentials()) && proto.kinesis_webrtc.AWSIAMCredentials.toObject(includeInstance, f),
channelInfo: (f = msg.getChannelInfo()) && proto.kinesis_webrtc.KinesisSignalingChannel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.KinesisCredentials}
 */
proto.kinesis_webrtc.KinesisCredentials.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.KinesisCredentials;
  return proto.kinesis_webrtc.KinesisCredentials.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.KinesisCredentials} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.KinesisCredentials}
 */
proto.kinesis_webrtc.KinesisCredentials.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 1:
      var value = new proto.kinesis_webrtc.AWSIAMCredentials;
      reader.readMessage(value,proto.kinesis_webrtc.AWSIAMCredentials.deserializeBinaryFromReader);
      msg.setCredentials(value);
      break;
    case 2:
      var value = new proto.kinesis_webrtc.KinesisSignalingChannel;
      reader.readMessage(value,proto.kinesis_webrtc.KinesisSignalingChannel.deserializeBinaryFromReader);
      msg.setChannelInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.KinesisCredentials.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.KinesisCredentials} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.KinesisCredentials.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCredentials();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.kinesis_webrtc.AWSIAMCredentials.serializeBinaryToWriter
    );
  }
  f = message.getChannelInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.kinesis_webrtc.KinesisSignalingChannel.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 utime = 3;
 * @return {number}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.KinesisCredentials} returns this
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AWSIAMCredentials credentials = 1;
 * @return {?proto.kinesis_webrtc.AWSIAMCredentials}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.getCredentials = function() {
  return /** @type{?proto.kinesis_webrtc.AWSIAMCredentials} */ (
    jspb.Message.getWrapperField(this, proto.kinesis_webrtc.AWSIAMCredentials, 1));
};


/**
 * @param {?proto.kinesis_webrtc.AWSIAMCredentials|undefined} value
 * @return {!proto.kinesis_webrtc.KinesisCredentials} returns this
*/
proto.kinesis_webrtc.KinesisCredentials.prototype.setCredentials = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kinesis_webrtc.KinesisCredentials} returns this
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.clearCredentials = function() {
  return this.setCredentials(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.hasCredentials = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional KinesisSignalingChannel channel_info = 2;
 * @return {?proto.kinesis_webrtc.KinesisSignalingChannel}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.getChannelInfo = function() {
  return /** @type{?proto.kinesis_webrtc.KinesisSignalingChannel} */ (
    jspb.Message.getWrapperField(this, proto.kinesis_webrtc.KinesisSignalingChannel, 2));
};


/**
 * @param {?proto.kinesis_webrtc.KinesisSignalingChannel|undefined} value
 * @return {!proto.kinesis_webrtc.KinesisCredentials} returns this
*/
proto.kinesis_webrtc.KinesisCredentials.prototype.setChannelInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.kinesis_webrtc.KinesisCredentials} returns this
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.clearChannelInfo = function() {
  return this.setChannelInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.kinesis_webrtc.KinesisCredentials.prototype.hasChannelInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.KinesisCredentialsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.KinesisCredentialsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.KinesisCredentialsResponse}
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.KinesisCredentialsResponse;
  return proto.kinesis_webrtc.KinesisCredentialsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.KinesisCredentialsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.KinesisCredentialsResponse}
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.kinesis_webrtc.SignalingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.KinesisCredentialsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.KinesisCredentialsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.KinesisCredentialsResponse} returns this
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SignalingStatus status = 2;
 * @return {!proto.kinesis_webrtc.SignalingStatus}
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.prototype.getStatus = function() {
  return /** @type {!proto.kinesis_webrtc.SignalingStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.kinesis_webrtc.SignalingStatus} value
 * @return {!proto.kinesis_webrtc.KinesisCredentialsResponse} returns this
 */
proto.kinesis_webrtc.KinesisCredentialsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.kinesis_webrtc.WebrtcMasterStatus.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.WebrtcMasterStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.WebrtcMasterStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.WebrtcMasterStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
signalingStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.kinesis_webrtc.ConnectedClient.toObject, includeInstance),
credentialsExpirationUclock: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.WebrtcMasterStatus;
  return proto.kinesis_webrtc.WebrtcMasterStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.WebrtcMasterStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.kinesis_webrtc.SignalingStatus} */ (reader.readEnum());
      msg.setSignalingStatus(value);
      break;
    case 4:
      var value = new proto.kinesis_webrtc.ConnectedClient;
      reader.readMessage(value,proto.kinesis_webrtc.ConnectedClient.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCredentialsExpirationUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.WebrtcMasterStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.WebrtcMasterStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.WebrtcMasterStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSignalingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.kinesis_webrtc.ConnectedClient.serializeBinaryToWriter
    );
  }
  f = message.getCredentialsExpirationUclock();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus} returns this
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SignalingStatus signaling_status = 2;
 * @return {!proto.kinesis_webrtc.SignalingStatus}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.getSignalingStatus = function() {
  return /** @type {!proto.kinesis_webrtc.SignalingStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.kinesis_webrtc.SignalingStatus} value
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus} returns this
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.setSignalingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated ConnectedClient clients = 4;
 * @return {!Array<!proto.kinesis_webrtc.ConnectedClient>}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.kinesis_webrtc.ConnectedClient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.kinesis_webrtc.ConnectedClient, 4));
};


/**
 * @param {!Array<!proto.kinesis_webrtc.ConnectedClient>} value
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus} returns this
*/
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.kinesis_webrtc.ConnectedClient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.kinesis_webrtc.ConnectedClient}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.kinesis_webrtc.ConnectedClient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus} returns this
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};


/**
 * optional uint64 credentials_expiration_uclock = 5;
 * @return {number}
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.getCredentialsExpirationUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.WebrtcMasterStatus} returns this
 */
proto.kinesis_webrtc.WebrtcMasterStatus.prototype.setCredentialsExpirationUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.ConnectedClient.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.ConnectedClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.ConnectedClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.ConnectedClient.toObject = function(includeInstance, msg) {
  var f, obj = {
clientId: jspb.Message.getFieldWithDefault(msg, 1, ""),
clientType: jspb.Message.getFieldWithDefault(msg, 2, 0),
lastRecvUtime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.ConnectedClient}
 */
proto.kinesis_webrtc.ConnectedClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.ConnectedClient;
  return proto.kinesis_webrtc.ConnectedClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.ConnectedClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.ConnectedClient}
 */
proto.kinesis_webrtc.ConnectedClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_config.ClientType.Enum} */ (reader.readEnum());
      msg.setClientType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastRecvUtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.ConnectedClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.ConnectedClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.ConnectedClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.ConnectedClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLastRecvUtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string client_id = 1;
 * @return {string}
 */
proto.kinesis_webrtc.ConnectedClient.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.ConnectedClient} returns this
 */
proto.kinesis_webrtc.ConnectedClient.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional cloud_config.ClientType.Enum client_type = 2;
 * @return {!proto.cloud_config.ClientType.Enum}
 */
proto.kinesis_webrtc.ConnectedClient.prototype.getClientType = function() {
  return /** @type {!proto.cloud_config.ClientType.Enum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_config.ClientType.Enum} value
 * @return {!proto.kinesis_webrtc.ConnectedClient} returns this
 */
proto.kinesis_webrtc.ConnectedClient.prototype.setClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int64 last_recv_utime = 3;
 * @return {number}
 */
proto.kinesis_webrtc.ConnectedClient.prototype.getLastRecvUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.ConnectedClient} returns this
 */
proto.kinesis_webrtc.ConnectedClient.prototype.setLastRecvUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.StopKinesis.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.StopKinesis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.StopKinesis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.StopKinesis.toObject = function(includeInstance, msg) {
  var f, obj = {
force: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.StopKinesis}
 */
proto.kinesis_webrtc.StopKinesis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.StopKinesis;
  return proto.kinesis_webrtc.StopKinesis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.StopKinesis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.StopKinesis}
 */
proto.kinesis_webrtc.StopKinesis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.StopKinesis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.StopKinesis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.StopKinesis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.StopKinesis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForce();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool force = 1;
 * @return {boolean}
 */
proto.kinesis_webrtc.StopKinesis.prototype.getForce = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.kinesis_webrtc.StopKinesis} returns this
 */
proto.kinesis_webrtc.StopKinesis.prototype.setForce = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.kinesis_webrtc.WebrtcClientStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.kinesis_webrtc.WebrtcClientStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.WebrtcClientStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
utime: jspb.Message.getFieldWithDefault(msg, 1, 0),
signalingStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
signalingChannel: jspb.Message.getFieldWithDefault(msg, 3, ""),
connectionStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
lastRecvUtime: jspb.Message.getFieldWithDefault(msg, 5, 0),
credentialsExpirationUclock: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus}
 */
proto.kinesis_webrtc.WebrtcClientStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.kinesis_webrtc.WebrtcClientStatus;
  return proto.kinesis_webrtc.WebrtcClientStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.kinesis_webrtc.WebrtcClientStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus}
 */
proto.kinesis_webrtc.WebrtcClientStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 2:
      var value = /** @type {!proto.kinesis_webrtc.SignalingStatus} */ (reader.readEnum());
      msg.setSignalingStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignalingChannel(value);
      break;
    case 4:
      var value = /** @type {!proto.kinesis_webrtc.PeerConnectionStatus} */ (reader.readEnum());
      msg.setConnectionStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastRecvUtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setCredentialsExpirationUclock(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.kinesis_webrtc.WebrtcClientStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.kinesis_webrtc.WebrtcClientStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.kinesis_webrtc.WebrtcClientStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSignalingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSignalingChannel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConnectionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLastRecvUtime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCredentialsExpirationUclock();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional int64 utime = 1;
 * @return {number}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus} returns this
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional SignalingStatus signaling_status = 2;
 * @return {!proto.kinesis_webrtc.SignalingStatus}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.getSignalingStatus = function() {
  return /** @type {!proto.kinesis_webrtc.SignalingStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.kinesis_webrtc.SignalingStatus} value
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus} returns this
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.setSignalingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string signaling_channel = 3;
 * @return {string}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.getSignalingChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus} returns this
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.setSignalingChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PeerConnectionStatus connection_status = 4;
 * @return {!proto.kinesis_webrtc.PeerConnectionStatus}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.getConnectionStatus = function() {
  return /** @type {!proto.kinesis_webrtc.PeerConnectionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.kinesis_webrtc.PeerConnectionStatus} value
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus} returns this
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.setConnectionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 last_recv_utime = 5;
 * @return {number}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.getLastRecvUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus} returns this
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.setLastRecvUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint64 credentials_expiration_uclock = 6;
 * @return {number}
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.getCredentialsExpirationUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.kinesis_webrtc.WebrtcClientStatus} returns this
 */
proto.kinesis_webrtc.WebrtcClientStatus.prototype.setCredentialsExpirationUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.kinesis_webrtc.SignalingStatus = {
  UNKNOWN: 0,
  SUCCESS: 1,
  FAILED: 2
};

/**
 * @enum {number}
 */
proto.kinesis_webrtc.PeerConnectionStatus = {
  NO_PEER: 0,
  PEER_CONNECTED: 1,
  PEER_DISCONNECTED: 2
};

goog.object.extend(exports, proto.kinesis_webrtc);
