// source: pbtypes/tools/cloud_api/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_gen_cloud_config_client_type_pb = require('../../../pbtypes/gen/cloud_config/client_type_pb.js');
goog.object.extend(proto, pbtypes_gen_cloud_config_client_type_pb);
var pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb = require('../../../pbtypes/infrastructure/drivers/attachment_manager/attachment_manager_pb.js');
goog.object.extend(proto, pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb);
var pbtypes_tools_cloud_api_release_pb = require('../../../pbtypes/tools/cloud_api/release_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_release_pb);
var pbtypes_tools_cloud_api_files_pb = require('../../../pbtypes/tools/cloud_api/files_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_files_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
var pbtypes_tools_cloud_api_landing_results_pb = require('../../../pbtypes/tools/cloud_api/landing_results_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_landing_results_pb);
var pbtypes_tools_cloud_api_ucon_pb = require('../../../pbtypes/tools/cloud_api/ucon_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_ucon_pb);
goog.exportSymbol('proto.cloud_api.AddFlightPathRequest', null, global);
goog.exportSymbol('proto.cloud_api.AggregateFlights', null, global);
goog.exportSymbol('proto.cloud_api.Flight', null, global);
goog.exportSymbol('proto.cloud_api.FlightStats', null, global);
goog.exportSymbol('proto.cloud_api.FlightStats.IdTypeEnum', null, global);
goog.exportSymbol('proto.cloud_api.Flights', null, global);
goog.exportSymbol('proto.cloud_api.UpdateFlightRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Flight = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Flight.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Flight, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Flight.displayName = 'proto.cloud_api.Flight';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Flights = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Flights.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Flights, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Flights.displayName = 'proto.cloud_api.Flights';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateFlightRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateFlightRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateFlightRequest.displayName = 'proto.cloud_api.UpdateFlightRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AggregateFlights = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AggregateFlights, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AggregateFlights.displayName = 'proto.cloud_api.AggregateFlights';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.FlightStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.FlightStats.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.FlightStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.FlightStats.displayName = 'proto.cloud_api.FlightStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.AddFlightPathRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.AddFlightPathRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.AddFlightPathRequest.displayName = 'proto.cloud_api.AddFlightPathRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Flight.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Flight.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Flight.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Flight} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Flight.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
handle: jspb.Message.getFieldWithDefault(msg, 2, ""),
vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
skydioSerial: jspb.Message.getFieldWithDefault(msg, 4, ""),
takeoffUserId: jspb.Message.getFieldWithDefault(msg, 5, ""),
takeoffClientType: jspb.Message.getFieldWithDefault(msg, 19, 0),
takeoffPhoneId: jspb.Message.getFieldWithDefault(msg, 6, ""),
takeoffDockId: jspb.Message.getFieldWithDefault(msg, 20, ""),
takeoffUtime: jspb.Message.getFieldWithDefault(msg, 7, 0),
landingUtime: jspb.Message.getFieldWithDefault(msg, 8, 0),
takeoffUclock: jspb.Message.getFieldWithDefault(msg, 9, 0),
landingUclock: jspb.Message.getFieldWithDefault(msg, 10, 0),
userEmail: jspb.Message.getFieldWithDefault(msg, 11, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 18, ""),
deployCommit: jspb.Message.getFieldWithDefault(msg, 13, ""),
releaseKey: jspb.Message.getFieldWithDefault(msg, 14, ""),
release: (f = msg.getRelease()) && pbtypes_tools_cloud_api_release_pb.ReleaseFile.toObject(includeInstance, f),
dataFilesList: jspb.Message.toObjectList(msg.getDataFilesList(),
    pbtypes_tools_cloud_api_files_pb.DataFilePb.toObject, includeInstance),
landingResults: (f = msg.getLandingResults()) && pbtypes_tools_cloud_api_landing_results_pb.LandingResults.toObject(includeInstance, f),
gimbalId: jspb.Message.getFieldWithDefault(msg, 22, ""),
vehicleAttachmentInfo: (f = msg.getVehicleAttachmentInfo()) && pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb.VehicleAttachmentInfo.toObject(includeInstance, f),
availableFiles: (f = msg.getAvailableFiles()) && pbtypes_tools_cloud_api_ucon_pb.AvailableFiles.toObject(includeInstance, f),
gimbalSerial: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Flight}
 */
proto.cloud_api.Flight.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Flight;
  return proto.cloud_api.Flight.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Flight} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Flight}
 */
proto.cloud_api.Flight.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkydioSerial(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffUserId(value);
      break;
    case 19:
      var value = /** @type {!proto.cloud_config.ClientType.Enum} */ (reader.readEnum());
      msg.setTakeoffClientType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffPhoneId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTakeoffDockId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTakeoffUtime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLandingUtime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTakeoffUclock(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLandingUclock(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeployCommit(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setReleaseKey(value);
      break;
    case 15:
      var value = new pbtypes_tools_cloud_api_release_pb.ReleaseFile;
      reader.readMessage(value,pbtypes_tools_cloud_api_release_pb.ReleaseFile.deserializeBinaryFromReader);
      msg.setRelease(value);
      break;
    case 16:
      var value = new pbtypes_tools_cloud_api_files_pb.DataFilePb;
      reader.readMessage(value,pbtypes_tools_cloud_api_files_pb.DataFilePb.deserializeBinaryFromReader);
      msg.addDataFiles(value);
      break;
    case 21:
      var value = new pbtypes_tools_cloud_api_landing_results_pb.LandingResults;
      reader.readMessage(value,pbtypes_tools_cloud_api_landing_results_pb.LandingResults.deserializeBinaryFromReader);
      msg.setLandingResults(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setGimbalId(value);
      break;
    case 24:
      var value = new pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb.VehicleAttachmentInfo;
      reader.readMessage(value,pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb.VehicleAttachmentInfo.deserializeBinaryFromReader);
      msg.setVehicleAttachmentInfo(value);
      break;
    case 25:
      var value = new pbtypes_tools_cloud_api_ucon_pb.AvailableFiles;
      reader.readMessage(value,pbtypes_tools_cloud_api_ucon_pb.AvailableFiles.deserializeBinaryFromReader);
      msg.setAvailableFiles(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setGimbalSerial(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Flight.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Flight.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Flight} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Flight.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSkydioSerial();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTakeoffUserId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTakeoffClientType();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getTakeoffPhoneId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTakeoffDockId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTakeoffUtime();
  if (f !== 0) {
    writer.writeUint64(
      7,
      f
    );
  }
  f = message.getLandingUtime();
  if (f !== 0) {
    writer.writeUint64(
      8,
      f
    );
  }
  f = message.getTakeoffUclock();
  if (f !== 0) {
    writer.writeUint64(
      9,
      f
    );
  }
  f = message.getLandingUclock();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDeployCommit();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getReleaseKey();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRelease();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      pbtypes_tools_cloud_api_release_pb.ReleaseFile.serializeBinaryToWriter
    );
  }
  f = message.getDataFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      pbtypes_tools_cloud_api_files_pb.DataFilePb.serializeBinaryToWriter
    );
  }
  f = message.getLandingResults();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      pbtypes_tools_cloud_api_landing_results_pb.LandingResults.serializeBinaryToWriter
    );
  }
  f = message.getGimbalId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getVehicleAttachmentInfo();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb.VehicleAttachmentInfo.serializeBinaryToWriter
    );
  }
  f = message.getAvailableFiles();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      pbtypes_tools_cloud_api_ucon_pb.AvailableFiles.serializeBinaryToWriter
    );
  }
  f = message.getGimbalSerial();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string handle = 2;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string skydio_serial = 4;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getSkydioSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setSkydioSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string takeoff_user_id = 5;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getTakeoffUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setTakeoffUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional cloud_config.ClientType.Enum takeoff_client_type = 19;
 * @return {!proto.cloud_config.ClientType.Enum}
 */
proto.cloud_api.Flight.prototype.getTakeoffClientType = function() {
  return /** @type {!proto.cloud_config.ClientType.Enum} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.cloud_config.ClientType.Enum} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setTakeoffClientType = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string takeoff_phone_id = 6;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getTakeoffPhoneId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setTakeoffPhoneId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string takeoff_dock_id = 20;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getTakeoffDockId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setTakeoffDockId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional uint64 takeoff_utime = 7;
 * @return {number}
 */
proto.cloud_api.Flight.prototype.getTakeoffUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setTakeoffUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint64 landing_utime = 8;
 * @return {number}
 */
proto.cloud_api.Flight.prototype.getLandingUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setLandingUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint64 takeoff_uclock = 9;
 * @return {number}
 */
proto.cloud_api.Flight.prototype.getTakeoffUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setTakeoffUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint64 landing_uclock = 10;
 * @return {number}
 */
proto.cloud_api.Flight.prototype.getLandingUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setLandingUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string user_email = 11;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string organization_id = 18;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string deploy_commit = 13;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getDeployCommit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setDeployCommit = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string release_key = 14;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getReleaseKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setReleaseKey = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional ReleaseFile release = 15;
 * @return {?proto.cloud_api.ReleaseFile}
 */
proto.cloud_api.Flight.prototype.getRelease = function() {
  return /** @type{?proto.cloud_api.ReleaseFile} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_release_pb.ReleaseFile, 15));
};


/**
 * @param {?proto.cloud_api.ReleaseFile|undefined} value
 * @return {!proto.cloud_api.Flight} returns this
*/
proto.cloud_api.Flight.prototype.setRelease = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.clearRelease = function() {
  return this.setRelease(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Flight.prototype.hasRelease = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated DataFilePb data_files = 16;
 * @return {!Array<!proto.cloud_api.DataFilePb>}
 */
proto.cloud_api.Flight.prototype.getDataFilesList = function() {
  return /** @type{!Array<!proto.cloud_api.DataFilePb>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_files_pb.DataFilePb, 16));
};


/**
 * @param {!Array<!proto.cloud_api.DataFilePb>} value
 * @return {!proto.cloud_api.Flight} returns this
*/
proto.cloud_api.Flight.prototype.setDataFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.cloud_api.DataFilePb=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.DataFilePb}
 */
proto.cloud_api.Flight.prototype.addDataFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.cloud_api.DataFilePb, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.clearDataFilesList = function() {
  return this.setDataFilesList([]);
};


/**
 * optional LandingResults landing_results = 21;
 * @return {?proto.cloud_api.LandingResults}
 */
proto.cloud_api.Flight.prototype.getLandingResults = function() {
  return /** @type{?proto.cloud_api.LandingResults} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_landing_results_pb.LandingResults, 21));
};


/**
 * @param {?proto.cloud_api.LandingResults|undefined} value
 * @return {!proto.cloud_api.Flight} returns this
*/
proto.cloud_api.Flight.prototype.setLandingResults = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.clearLandingResults = function() {
  return this.setLandingResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Flight.prototype.hasLandingResults = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string gimbal_id = 22;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getGimbalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setGimbalId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional attachment_manager.VehicleAttachmentInfo vehicle_attachment_info = 24;
 * @return {?proto.attachment_manager.VehicleAttachmentInfo}
 */
proto.cloud_api.Flight.prototype.getVehicleAttachmentInfo = function() {
  return /** @type{?proto.attachment_manager.VehicleAttachmentInfo} */ (
    jspb.Message.getWrapperField(this, pbtypes_infrastructure_drivers_attachment_manager_attachment_manager_pb.VehicleAttachmentInfo, 24));
};


/**
 * @param {?proto.attachment_manager.VehicleAttachmentInfo|undefined} value
 * @return {!proto.cloud_api.Flight} returns this
*/
proto.cloud_api.Flight.prototype.setVehicleAttachmentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.clearVehicleAttachmentInfo = function() {
  return this.setVehicleAttachmentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Flight.prototype.hasVehicleAttachmentInfo = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional AvailableFiles available_files = 25;
 * @return {?proto.cloud_api.AvailableFiles}
 */
proto.cloud_api.Flight.prototype.getAvailableFiles = function() {
  return /** @type{?proto.cloud_api.AvailableFiles} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_ucon_pb.AvailableFiles, 25));
};


/**
 * @param {?proto.cloud_api.AvailableFiles|undefined} value
 * @return {!proto.cloud_api.Flight} returns this
*/
proto.cloud_api.Flight.prototype.setAvailableFiles = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.clearAvailableFiles = function() {
  return this.setAvailableFiles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Flight.prototype.hasAvailableFiles = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string gimbal_serial = 26;
 * @return {string}
 */
proto.cloud_api.Flight.prototype.getGimbalSerial = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Flight} returns this
 */
proto.cloud_api.Flight.prototype.setGimbalSerial = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Flights.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Flights.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Flights.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Flights} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Flights.toObject = function(includeInstance, msg) {
  var f, obj = {
flightsList: jspb.Message.toObjectList(msg.getFlightsList(),
    proto.cloud_api.Flight.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Flights}
 */
proto.cloud_api.Flights.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Flights;
  return proto.cloud_api.Flights.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Flights} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Flights}
 */
proto.cloud_api.Flights.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Flight;
      reader.readMessage(value,proto.cloud_api.Flight.deserializeBinaryFromReader);
      msg.addFlights(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Flights.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Flights.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Flights} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Flights.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.Flight.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Flight flights = 1;
 * @return {!Array<!proto.cloud_api.Flight>}
 */
proto.cloud_api.Flights.prototype.getFlightsList = function() {
  return /** @type{!Array<!proto.cloud_api.Flight>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Flight, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Flight>} value
 * @return {!proto.cloud_api.Flights} returns this
*/
proto.cloud_api.Flights.prototype.setFlightsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Flight=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Flight}
 */
proto.cloud_api.Flights.prototype.addFlights = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Flight, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Flights} returns this
 */
proto.cloud_api.Flights.prototype.clearFlightsList = function() {
  return this.setFlightsList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Flights.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Flights} returns this
*/
proto.cloud_api.Flights.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Flights} returns this
 */
proto.cloud_api.Flights.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Flights.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateFlightRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateFlightRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateFlightRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateFlightRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
flight: (f = msg.getFlight()) && proto.cloud_api.Flight.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateFlightRequest}
 */
proto.cloud_api.UpdateFlightRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateFlightRequest;
  return proto.cloud_api.UpdateFlightRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateFlightRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateFlightRequest}
 */
proto.cloud_api.UpdateFlightRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Flight;
      reader.readMessage(value,proto.cloud_api.Flight.deserializeBinaryFromReader);
      msg.setFlight(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateFlightRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateFlightRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateFlightRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateFlightRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlight();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.Flight.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Flight flight = 1;
 * @return {?proto.cloud_api.Flight}
 */
proto.cloud_api.UpdateFlightRequest.prototype.getFlight = function() {
  return /** @type{?proto.cloud_api.Flight} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.Flight, 1));
};


/**
 * @param {?proto.cloud_api.Flight|undefined} value
 * @return {!proto.cloud_api.UpdateFlightRequest} returns this
*/
proto.cloud_api.UpdateFlightRequest.prototype.setFlight = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateFlightRequest} returns this
 */
proto.cloud_api.UpdateFlightRequest.prototype.clearFlight = function() {
  return this.setFlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateFlightRequest.prototype.hasFlight = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateFlightRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateFlightRequest} returns this
*/
proto.cloud_api.UpdateFlightRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateFlightRequest} returns this
 */
proto.cloud_api.UpdateFlightRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateFlightRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AggregateFlights.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AggregateFlights.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AggregateFlights} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AggregateFlights.toObject = function(includeInstance, msg) {
  var f, obj = {
id: jspb.Message.getFieldWithDefault(msg, 1, ""),
totalFlights: jspb.Message.getFieldWithDefault(msg, 2, 0),
totalTime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AggregateFlights}
 */
proto.cloud_api.AggregateFlights.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AggregateFlights;
  return proto.cloud_api.AggregateFlights.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AggregateFlights} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AggregateFlights}
 */
proto.cloud_api.AggregateFlights.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalFlights(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AggregateFlights.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AggregateFlights.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AggregateFlights} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AggregateFlights.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalFlights();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTotalTime();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.cloud_api.AggregateFlights.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AggregateFlights} returns this
 */
proto.cloud_api.AggregateFlights.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 total_flights = 2;
 * @return {number}
 */
proto.cloud_api.AggregateFlights.prototype.getTotalFlights = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.AggregateFlights} returns this
 */
proto.cloud_api.AggregateFlights.prototype.setTotalFlights = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 total_time = 3;
 * @return {number}
 */
proto.cloud_api.AggregateFlights.prototype.getTotalTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.AggregateFlights} returns this
 */
proto.cloud_api.AggregateFlights.prototype.setTotalTime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.FlightStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.FlightStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.FlightStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.FlightStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FlightStats.toObject = function(includeInstance, msg) {
  var f, obj = {
statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.cloud_api.AggregateFlights.toObject, includeInstance),
idType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.FlightStats}
 */
proto.cloud_api.FlightStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.FlightStats;
  return proto.cloud_api.FlightStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.FlightStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.FlightStats}
 */
proto.cloud_api.FlightStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.AggregateFlights;
      reader.readMessage(value,proto.cloud_api.AggregateFlights.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.FlightStats.IdTypeEnum} */ (reader.readEnum());
      msg.setIdType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.FlightStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.FlightStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.FlightStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.FlightStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.AggregateFlights.serializeBinaryToWriter
    );
  }
  f = message.getIdType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.FlightStats.IdTypeEnum = {
  USER: 0,
  VEHICLE: 1,
  HOUR: 2,
  DAY: 3,
  MONTH: 4
};

/**
 * repeated AggregateFlights stats = 1;
 * @return {!Array<!proto.cloud_api.AggregateFlights>}
 */
proto.cloud_api.FlightStats.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.cloud_api.AggregateFlights>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.AggregateFlights, 1));
};


/**
 * @param {!Array<!proto.cloud_api.AggregateFlights>} value
 * @return {!proto.cloud_api.FlightStats} returns this
*/
proto.cloud_api.FlightStats.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.AggregateFlights=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.AggregateFlights}
 */
proto.cloud_api.FlightStats.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.AggregateFlights, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.FlightStats} returns this
 */
proto.cloud_api.FlightStats.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};


/**
 * optional IdTypeEnum id_type = 2;
 * @return {!proto.cloud_api.FlightStats.IdTypeEnum}
 */
proto.cloud_api.FlightStats.prototype.getIdType = function() {
  return /** @type {!proto.cloud_api.FlightStats.IdTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.FlightStats.IdTypeEnum} value
 * @return {!proto.cloud_api.FlightStats} returns this
 */
proto.cloud_api.FlightStats.prototype.setIdType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.AddFlightPathRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.AddFlightPathRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.AddFlightPathRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AddFlightPathRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
telemetryJson: jspb.Message.getFieldWithDefault(msg, 2, ""),
flightPath: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.AddFlightPathRequest}
 */
proto.cloud_api.AddFlightPathRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.AddFlightPathRequest;
  return proto.cloud_api.AddFlightPathRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.AddFlightPathRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.AddFlightPathRequest}
 */
proto.cloud_api.AddFlightPathRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelemetryJson(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightPath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.AddFlightPathRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.AddFlightPathRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.AddFlightPathRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.AddFlightPathRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTelemetryJson();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightPath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.cloud_api.AddFlightPathRequest.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AddFlightPathRequest} returns this
 */
proto.cloud_api.AddFlightPathRequest.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string telemetry_json = 2;
 * @return {string}
 */
proto.cloud_api.AddFlightPathRequest.prototype.getTelemetryJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AddFlightPathRequest} returns this
 */
proto.cloud_api.AddFlightPathRequest.prototype.setTelemetryJson = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_path = 3;
 * @return {string}
 */
proto.cloud_api.AddFlightPathRequest.prototype.getFlightPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.AddFlightPathRequest} returns this
 */
proto.cloud_api.AddFlightPathRequest.prototype.setFlightPath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.cloud_api);
