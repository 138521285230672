// source: pbtypes/gen/scan_planner/component_request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var pbtypes_gen_scan_planner_area_scan_request_pb = require('../../../pbtypes/gen/scan_planner/area_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_area_scan_request_pb);
var pbtypes_gen_scan_planner_contour_scan_request_pb = require('../../../pbtypes/gen/scan_planner/contour_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_contour_scan_request_pb);
var pbtypes_gen_scan_planner_exploration_orbit_request_pb = require('../../../pbtypes/gen/scan_planner/exploration_orbit_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_exploration_orbit_request_pb);
var pbtypes_gen_scan_planner_long_range_explore_request_pb = require('../../../pbtypes/gen/scan_planner/long_range_explore_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_long_range_explore_request_pb);
var pbtypes_gen_scan_planner_orbit_scan_request_pb = require('../../../pbtypes/gen/scan_planner/orbit_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_orbit_scan_request_pb);
var pbtypes_gen_scan_planner_radial_scan_request_pb = require('../../../pbtypes/gen/scan_planner/radial_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_radial_scan_request_pb);
var pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb = require('../../../pbtypes/gen/scan_planner/single_column_inspection_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb);
var pbtypes_gen_scan_planner_single_orbit_request_pb = require('../../../pbtypes/gen/scan_planner/single_orbit_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_single_orbit_request_pb);
var pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb = require('../../../pbtypes/gen/scan_planner/structure_column_inspection_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb);
var pbtypes_gen_scan_planner_surface_scan_request_pb = require('../../../pbtypes/gen/scan_planner/surface_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_surface_scan_request_pb);
var pbtypes_gen_scan_planner_vertex_column_scan_request_pb = require('../../../pbtypes/gen/scan_planner/vertex_column_scan_request_pb.js');
goog.object.extend(proto, pbtypes_gen_scan_planner_vertex_column_scan_request_pb);
var pbtypes_gen_surface_scan_inspection_type_pb = require('../../../pbtypes/gen/surface_scan/inspection_type_pb.js');
goog.object.extend(proto, pbtypes_gen_surface_scan_inspection_type_pb);
goog.exportSymbol('proto.scan_planner.ComponentRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scan_planner.ComponentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scan_planner.ComponentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scan_planner.ComponentRequest.displayName = 'proto.scan_planner.ComponentRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scan_planner.ComponentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scan_planner.ComponentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scan_planner.ComponentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.ComponentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
inspectionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
surfaceScanRequest: (f = msg.getSurfaceScanRequest()) && pbtypes_gen_scan_planner_surface_scan_request_pb.SurfaceScanRequest.toObject(includeInstance, f),
areaScanRequest: (f = msg.getAreaScanRequest()) && pbtypes_gen_scan_planner_area_scan_request_pb.AreaScanRequest.toObject(includeInstance, f),
radialScanRequest: (f = msg.getRadialScanRequest()) && pbtypes_gen_scan_planner_radial_scan_request_pb.RadialScanRequest.toObject(includeInstance, f),
contourScanRequest: (f = msg.getContourScanRequest()) && pbtypes_gen_scan_planner_contour_scan_request_pb.ContourScanRequest.toObject(includeInstance, f),
orbitScanRequest: (f = msg.getOrbitScanRequest()) && pbtypes_gen_scan_planner_orbit_scan_request_pb.OrbitScanRequest.toObject(includeInstance, f),
vertexColumnScanRequest: (f = msg.getVertexColumnScanRequest()) && pbtypes_gen_scan_planner_vertex_column_scan_request_pb.VertexColumnScanRequest.toObject(includeInstance, f),
singleOrbitRequest: (f = msg.getSingleOrbitRequest()) && pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest.toObject(includeInstance, f),
explorationOrbitRequest: (f = msg.getExplorationOrbitRequest()) && pbtypes_gen_scan_planner_exploration_orbit_request_pb.ExplorationOrbitRequest.toObject(includeInstance, f),
longRangeExploreRequest: (f = msg.getLongRangeExploreRequest()) && pbtypes_gen_scan_planner_long_range_explore_request_pb.LongRangeExploreRequest.toObject(includeInstance, f),
structureColumnInspectionScanRequest: (f = msg.getStructureColumnInspectionScanRequest()) && pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb.StructureColumnInspectionScanRequest.toObject(includeInstance, f),
singleColumnInspectionScanRequest: (f = msg.getSingleColumnInspectionScanRequest()) && pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb.SingleColumnInspectionScanRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scan_planner.ComponentRequest}
 */
proto.scan_planner.ComponentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scan_planner.ComponentRequest;
  return proto.scan_planner.ComponentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scan_planner.ComponentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scan_planner.ComponentRequest}
 */
proto.scan_planner.ComponentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.surface_scan.InspectionType.Enum} */ (reader.readEnum());
      msg.setInspectionType(value);
      break;
    case 2:
      var value = new pbtypes_gen_scan_planner_surface_scan_request_pb.SurfaceScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_surface_scan_request_pb.SurfaceScanRequest.deserializeBinaryFromReader);
      msg.setSurfaceScanRequest(value);
      break;
    case 3:
      var value = new pbtypes_gen_scan_planner_area_scan_request_pb.AreaScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_area_scan_request_pb.AreaScanRequest.deserializeBinaryFromReader);
      msg.setAreaScanRequest(value);
      break;
    case 4:
      var value = new pbtypes_gen_scan_planner_radial_scan_request_pb.RadialScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_radial_scan_request_pb.RadialScanRequest.deserializeBinaryFromReader);
      msg.setRadialScanRequest(value);
      break;
    case 5:
      var value = new pbtypes_gen_scan_planner_contour_scan_request_pb.ContourScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_contour_scan_request_pb.ContourScanRequest.deserializeBinaryFromReader);
      msg.setContourScanRequest(value);
      break;
    case 6:
      var value = new pbtypes_gen_scan_planner_orbit_scan_request_pb.OrbitScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_orbit_scan_request_pb.OrbitScanRequest.deserializeBinaryFromReader);
      msg.setOrbitScanRequest(value);
      break;
    case 7:
      var value = new pbtypes_gen_scan_planner_vertex_column_scan_request_pb.VertexColumnScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_vertex_column_scan_request_pb.VertexColumnScanRequest.deserializeBinaryFromReader);
      msg.setVertexColumnScanRequest(value);
      break;
    case 8:
      var value = new pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest.deserializeBinaryFromReader);
      msg.setSingleOrbitRequest(value);
      break;
    case 11:
      var value = new pbtypes_gen_scan_planner_exploration_orbit_request_pb.ExplorationOrbitRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_exploration_orbit_request_pb.ExplorationOrbitRequest.deserializeBinaryFromReader);
      msg.setExplorationOrbitRequest(value);
      break;
    case 9:
      var value = new pbtypes_gen_scan_planner_long_range_explore_request_pb.LongRangeExploreRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_long_range_explore_request_pb.LongRangeExploreRequest.deserializeBinaryFromReader);
      msg.setLongRangeExploreRequest(value);
      break;
    case 10:
      var value = new pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb.StructureColumnInspectionScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb.StructureColumnInspectionScanRequest.deserializeBinaryFromReader);
      msg.setStructureColumnInspectionScanRequest(value);
      break;
    case 12:
      var value = new pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb.SingleColumnInspectionScanRequest;
      reader.readMessage(value,pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb.SingleColumnInspectionScanRequest.deserializeBinaryFromReader);
      msg.setSingleColumnInspectionScanRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scan_planner.ComponentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scan_planner.ComponentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scan_planner.ComponentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scan_planner.ComponentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInspectionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSurfaceScanRequest();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_gen_scan_planner_surface_scan_request_pb.SurfaceScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getAreaScanRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      pbtypes_gen_scan_planner_area_scan_request_pb.AreaScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getRadialScanRequest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      pbtypes_gen_scan_planner_radial_scan_request_pb.RadialScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getContourScanRequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      pbtypes_gen_scan_planner_contour_scan_request_pb.ContourScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getOrbitScanRequest();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      pbtypes_gen_scan_planner_orbit_scan_request_pb.OrbitScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getVertexColumnScanRequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      pbtypes_gen_scan_planner_vertex_column_scan_request_pb.VertexColumnScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getSingleOrbitRequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest.serializeBinaryToWriter
    );
  }
  f = message.getExplorationOrbitRequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      pbtypes_gen_scan_planner_exploration_orbit_request_pb.ExplorationOrbitRequest.serializeBinaryToWriter
    );
  }
  f = message.getLongRangeExploreRequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      pbtypes_gen_scan_planner_long_range_explore_request_pb.LongRangeExploreRequest.serializeBinaryToWriter
    );
  }
  f = message.getStructureColumnInspectionScanRequest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb.StructureColumnInspectionScanRequest.serializeBinaryToWriter
    );
  }
  f = message.getSingleColumnInspectionScanRequest();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb.SingleColumnInspectionScanRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional surface_scan.InspectionType.Enum inspection_type = 1;
 * @return {!proto.surface_scan.InspectionType.Enum}
 */
proto.scan_planner.ComponentRequest.prototype.getInspectionType = function() {
  return /** @type {!proto.surface_scan.InspectionType.Enum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.surface_scan.InspectionType.Enum} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.setInspectionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SurfaceScanRequest surface_scan_request = 2;
 * @return {?proto.scan_planner.SurfaceScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getSurfaceScanRequest = function() {
  return /** @type{?proto.scan_planner.SurfaceScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_surface_scan_request_pb.SurfaceScanRequest, 2));
};


/**
 * @param {?proto.scan_planner.SurfaceScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setSurfaceScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearSurfaceScanRequest = function() {
  return this.setSurfaceScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasSurfaceScanRequest = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AreaScanRequest area_scan_request = 3;
 * @return {?proto.scan_planner.AreaScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getAreaScanRequest = function() {
  return /** @type{?proto.scan_planner.AreaScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_area_scan_request_pb.AreaScanRequest, 3));
};


/**
 * @param {?proto.scan_planner.AreaScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setAreaScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearAreaScanRequest = function() {
  return this.setAreaScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasAreaScanRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RadialScanRequest radial_scan_request = 4;
 * @return {?proto.scan_planner.RadialScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getRadialScanRequest = function() {
  return /** @type{?proto.scan_planner.RadialScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_radial_scan_request_pb.RadialScanRequest, 4));
};


/**
 * @param {?proto.scan_planner.RadialScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setRadialScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearRadialScanRequest = function() {
  return this.setRadialScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasRadialScanRequest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ContourScanRequest contour_scan_request = 5;
 * @return {?proto.scan_planner.ContourScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getContourScanRequest = function() {
  return /** @type{?proto.scan_planner.ContourScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_contour_scan_request_pb.ContourScanRequest, 5));
};


/**
 * @param {?proto.scan_planner.ContourScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setContourScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearContourScanRequest = function() {
  return this.setContourScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasContourScanRequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional OrbitScanRequest orbit_scan_request = 6;
 * @return {?proto.scan_planner.OrbitScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getOrbitScanRequest = function() {
  return /** @type{?proto.scan_planner.OrbitScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_orbit_scan_request_pb.OrbitScanRequest, 6));
};


/**
 * @param {?proto.scan_planner.OrbitScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setOrbitScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearOrbitScanRequest = function() {
  return this.setOrbitScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasOrbitScanRequest = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional VertexColumnScanRequest vertex_column_scan_request = 7;
 * @return {?proto.scan_planner.VertexColumnScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getVertexColumnScanRequest = function() {
  return /** @type{?proto.scan_planner.VertexColumnScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_vertex_column_scan_request_pb.VertexColumnScanRequest, 7));
};


/**
 * @param {?proto.scan_planner.VertexColumnScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setVertexColumnScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearVertexColumnScanRequest = function() {
  return this.setVertexColumnScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasVertexColumnScanRequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SingleOrbitRequest single_orbit_request = 8;
 * @return {?proto.scan_planner.SingleOrbitRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getSingleOrbitRequest = function() {
  return /** @type{?proto.scan_planner.SingleOrbitRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_single_orbit_request_pb.SingleOrbitRequest, 8));
};


/**
 * @param {?proto.scan_planner.SingleOrbitRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setSingleOrbitRequest = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearSingleOrbitRequest = function() {
  return this.setSingleOrbitRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasSingleOrbitRequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ExplorationOrbitRequest exploration_orbit_request = 11;
 * @return {?proto.scan_planner.ExplorationOrbitRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getExplorationOrbitRequest = function() {
  return /** @type{?proto.scan_planner.ExplorationOrbitRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_exploration_orbit_request_pb.ExplorationOrbitRequest, 11));
};


/**
 * @param {?proto.scan_planner.ExplorationOrbitRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setExplorationOrbitRequest = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearExplorationOrbitRequest = function() {
  return this.setExplorationOrbitRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasExplorationOrbitRequest = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional LongRangeExploreRequest long_range_explore_request = 9;
 * @return {?proto.scan_planner.LongRangeExploreRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getLongRangeExploreRequest = function() {
  return /** @type{?proto.scan_planner.LongRangeExploreRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_long_range_explore_request_pb.LongRangeExploreRequest, 9));
};


/**
 * @param {?proto.scan_planner.LongRangeExploreRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setLongRangeExploreRequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearLongRangeExploreRequest = function() {
  return this.setLongRangeExploreRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasLongRangeExploreRequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional StructureColumnInspectionScanRequest structure_column_inspection_scan_request = 10;
 * @return {?proto.scan_planner.StructureColumnInspectionScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getStructureColumnInspectionScanRequest = function() {
  return /** @type{?proto.scan_planner.StructureColumnInspectionScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_structure_column_inspection_scan_request_pb.StructureColumnInspectionScanRequest, 10));
};


/**
 * @param {?proto.scan_planner.StructureColumnInspectionScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setStructureColumnInspectionScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearStructureColumnInspectionScanRequest = function() {
  return this.setStructureColumnInspectionScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasStructureColumnInspectionScanRequest = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SingleColumnInspectionScanRequest single_column_inspection_scan_request = 12;
 * @return {?proto.scan_planner.SingleColumnInspectionScanRequest}
 */
proto.scan_planner.ComponentRequest.prototype.getSingleColumnInspectionScanRequest = function() {
  return /** @type{?proto.scan_planner.SingleColumnInspectionScanRequest} */ (
    jspb.Message.getWrapperField(this, pbtypes_gen_scan_planner_single_column_inspection_scan_request_pb.SingleColumnInspectionScanRequest, 12));
};


/**
 * @param {?proto.scan_planner.SingleColumnInspectionScanRequest|undefined} value
 * @return {!proto.scan_planner.ComponentRequest} returns this
*/
proto.scan_planner.ComponentRequest.prototype.setSingleColumnInspectionScanRequest = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scan_planner.ComponentRequest} returns this
 */
proto.scan_planner.ComponentRequest.prototype.clearSingleColumnInspectionScanRequest = function() {
  return this.setSingleColumnInspectionScanRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scan_planner.ComponentRequest.prototype.hasSingleColumnInspectionScanRequest = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.scan_planner);
