// source: pbtypes/tools/cloud_api/organization.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.object.extend(proto, google_protobuf_field_mask_pb);
var pbtypes_tools_cloud_api_enums_pb = require('../../../pbtypes/tools/cloud_api/enums_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_enums_pb);
var pbtypes_tools_cloud_api_pagination_pb = require('../../../pbtypes/tools/cloud_api/pagination_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_pagination_pb);
var pbtypes_tools_cloud_api_stats_pb = require('../../../pbtypes/tools/cloud_api/stats_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_stats_pb);
var pbtypes_tools_cloud_api_user_pb = require('../../../pbtypes/tools/cloud_api/user_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_user_pb);
var pbtypes_tools_cloud_api_livestream_settings_pb = require('../../../pbtypes/tools/cloud_api/livestream_settings_pb.js');
goog.object.extend(proto, pbtypes_tools_cloud_api_livestream_settings_pb);
goog.exportSymbol('proto.cloud_api.InviteUserToOrganizationRequest', null, global);
goog.exportSymbol('proto.cloud_api.InviteUserToOrganizationResponse', null, global);
goog.exportSymbol('proto.cloud_api.Organization', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationGroupLink', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationLogoUploadRequest', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationPilotStats', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationPilotStats.IdTypeEnum', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationSettings', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationSkillsetLink', null, global);
goog.exportSymbol('proto.cloud_api.OrganizationStats', null, global);
goog.exportSymbol('proto.cloud_api.Organizations', null, global);
goog.exportSymbol('proto.cloud_api.ResourceGroup', null, global);
goog.exportSymbol('proto.cloud_api.StorageStat', null, global);
goog.exportSymbol('proto.cloud_api.StorageStat.DurationType', null, global);
goog.exportSymbol('proto.cloud_api.StorageStats', null, global);
goog.exportSymbol('proto.cloud_api.UpdateOrganizationRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Organization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Organization.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Organization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Organization.displayName = 'proto.cloud_api.Organization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationGroupLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OrganizationGroupLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationGroupLink.displayName = 'proto.cloud_api.OrganizationGroupLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.Organizations = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.Organizations.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.Organizations, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.Organizations.displayName = 'proto.cloud_api.Organizations';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.UpdateOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.UpdateOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.UpdateOrganizationRequest.displayName = 'proto.cloud_api.UpdateOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.InviteUserToOrganizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.InviteUserToOrganizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.InviteUserToOrganizationRequest.displayName = 'proto.cloud_api.InviteUserToOrganizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.InviteUserToOrganizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.InviteUserToOrganizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.InviteUserToOrganizationResponse.displayName = 'proto.cloud_api.InviteUserToOrganizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationSkillsetLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OrganizationSkillsetLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationSkillsetLink.displayName = 'proto.cloud_api.OrganizationSkillsetLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.OrganizationStats.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.OrganizationStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationStats.displayName = 'proto.cloud_api.OrganizationStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationPilotStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.OrganizationPilotStats.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.OrganizationPilotStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationPilotStats.displayName = 'proto.cloud_api.OrganizationPilotStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.StorageStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.StorageStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.StorageStat.displayName = 'proto.cloud_api.StorageStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.StorageStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cloud_api.StorageStats.repeatedFields_, null);
};
goog.inherits(proto.cloud_api.StorageStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.StorageStats.displayName = 'proto.cloud_api.StorageStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationLogoUploadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OrganizationLogoUploadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationLogoUploadRequest.displayName = 'proto.cloud_api.OrganizationLogoUploadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.OrganizationSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.OrganizationSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.OrganizationSettings.displayName = 'proto.cloud_api.OrganizationSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.ResourceGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.ResourceGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.ResourceGroup.displayName = 'proto.cloud_api.ResourceGroup';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Organization.repeatedFields_ = [11,3,4,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Organization.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Organization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Organization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Organization.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
name: jspb.Message.getFieldWithDefault(msg, 2, ""),
resourceGroupsList: jspb.Message.toObjectList(msg.getResourceGroupsList(),
    proto.cloud_api.ResourceGroup.toObject, includeInstance),
groupLinksList: jspb.Message.toObjectList(msg.getGroupLinksList(),
    proto.cloud_api.OrganizationGroupLink.toObject, includeInstance),
usersList: jspb.Message.toObjectList(msg.getUsersList(),
    pbtypes_tools_cloud_api_user_pb.User.toObject, includeInstance),
userCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
userEmailSuffix: jspb.Message.getFieldWithDefault(msg, 6, ""),
userEmailSuffixDefaultOrgPermission: jspb.Message.getFieldWithDefault(msg, 9, 0),
dataLevel: jspb.Message.getFieldWithDefault(msg, 10, 0),
skillsetLinksList: jspb.Message.toObjectList(msg.getSkillsetLinksList(),
    proto.cloud_api.OrganizationSkillsetLink.toObject, includeInstance),
switchesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
permissionGrantedForProductImprovement: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
dataUsageRights: jspb.Message.getFieldWithDefault(msg, 13, 0),
orgTier: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Organization}
 */
proto.cloud_api.Organization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Organization;
  return proto.cloud_api.Organization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Organization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Organization}
 */
proto.cloud_api.Organization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = new proto.cloud_api.ResourceGroup;
      reader.readMessage(value,proto.cloud_api.ResourceGroup.deserializeBinaryFromReader);
      msg.addResourceGroups(value);
      break;
    case 3:
      var value = new proto.cloud_api.OrganizationGroupLink;
      reader.readMessage(value,proto.cloud_api.OrganizationGroupLink.deserializeBinaryFromReader);
      msg.addGroupLinks(value);
      break;
    case 4:
      var value = new pbtypes_tools_cloud_api_user_pb.User;
      reader.readMessage(value,pbtypes_tools_cloud_api_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserCount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmailSuffix(value);
      break;
    case 9:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setUserEmailSuffixDefaultOrgPermission(value);
      break;
    case 10:
      var value = /** @type {!proto.cloud_api.DataLevel.LevelEnum} */ (reader.readEnum());
      msg.setDataLevel(value);
      break;
    case 7:
      var value = new proto.cloud_api.OrganizationSkillsetLink;
      reader.readMessage(value,proto.cloud_api.OrganizationSkillsetLink.deserializeBinaryFromReader);
      msg.addSkillsetLinks(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.cloud_api.FeatureSwitch.FeatureSwitchEnum>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSwitches(values[i]);
      }
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPermissionGrantedForProductImprovement(value);
      break;
    case 13:
      var value = /** @type {!proto.cloud_api.DataUsageRights.DataUsageRightsEnum} */ (reader.readEnum());
      msg.setDataUsageRights(value);
      break;
    case 14:
      var value = /** @type {!proto.cloud_api.OrgTier.OrgTierEnum} */ (reader.readEnum());
      msg.setOrgTier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Organization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Organization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Organization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Organization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResourceGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.cloud_api.ResourceGroup.serializeBinaryToWriter
    );
  }
  f = message.getGroupLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cloud_api.OrganizationGroupLink.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      pbtypes_tools_cloud_api_user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getUserCount();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUserEmailSuffix();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUserEmailSuffixDefaultOrgPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDataLevel();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getSkillsetLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.cloud_api.OrganizationSkillsetLink.serializeBinaryToWriter
    );
  }
  f = message.getSwitchesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
  f = message.getPermissionGrantedForProductImprovement();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getDataUsageRights();
  if (f !== 0.0) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getOrgTier();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.Organization.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cloud_api.Organization.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ResourceGroup resource_groups = 11;
 * @return {!Array<!proto.cloud_api.ResourceGroup>}
 */
proto.cloud_api.Organization.prototype.getResourceGroupsList = function() {
  return /** @type{!Array<!proto.cloud_api.ResourceGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.ResourceGroup, 11));
};


/**
 * @param {!Array<!proto.cloud_api.ResourceGroup>} value
 * @return {!proto.cloud_api.Organization} returns this
*/
proto.cloud_api.Organization.prototype.setResourceGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.cloud_api.ResourceGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.ResourceGroup}
 */
proto.cloud_api.Organization.prototype.addResourceGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.cloud_api.ResourceGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.clearResourceGroupsList = function() {
  return this.setResourceGroupsList([]);
};


/**
 * repeated OrganizationGroupLink group_links = 3;
 * @return {!Array<!proto.cloud_api.OrganizationGroupLink>}
 */
proto.cloud_api.Organization.prototype.getGroupLinksList = function() {
  return /** @type{!Array<!proto.cloud_api.OrganizationGroupLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.OrganizationGroupLink, 3));
};


/**
 * @param {!Array<!proto.cloud_api.OrganizationGroupLink>} value
 * @return {!proto.cloud_api.Organization} returns this
*/
proto.cloud_api.Organization.prototype.setGroupLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cloud_api.OrganizationGroupLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.OrganizationGroupLink}
 */
proto.cloud_api.Organization.prototype.addGroupLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cloud_api.OrganizationGroupLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.clearGroupLinksList = function() {
  return this.setGroupLinksList([]);
};


/**
 * repeated User users = 4;
 * @return {!Array<!proto.cloud_api.User>}
 */
proto.cloud_api.Organization.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.cloud_api.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_user_pb.User, 4));
};


/**
 * @param {!Array<!proto.cloud_api.User>} value
 * @return {!proto.cloud_api.Organization} returns this
*/
proto.cloud_api.Organization.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cloud_api.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.User}
 */
proto.cloud_api.Organization.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cloud_api.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional uint32 user_count = 5;
 * @return {number}
 */
proto.cloud_api.Organization.prototype.getUserCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setUserCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string user_email_suffix = 6;
 * @return {string}
 */
proto.cloud_api.Organization.prototype.getUserEmailSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setUserEmailSuffix = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional OrgPermission.PermEnum user_email_suffix_default_org_permission = 9;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.Organization.prototype.getUserEmailSuffixDefaultOrgPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setUserEmailSuffixDefaultOrgPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional DataLevel.LevelEnum data_level = 10;
 * @return {!proto.cloud_api.DataLevel.LevelEnum}
 */
proto.cloud_api.Organization.prototype.getDataLevel = function() {
  return /** @type {!proto.cloud_api.DataLevel.LevelEnum} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.cloud_api.DataLevel.LevelEnum} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setDataLevel = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * repeated OrganizationSkillsetLink skillset_links = 7;
 * @return {!Array<!proto.cloud_api.OrganizationSkillsetLink>}
 */
proto.cloud_api.Organization.prototype.getSkillsetLinksList = function() {
  return /** @type{!Array<!proto.cloud_api.OrganizationSkillsetLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.OrganizationSkillsetLink, 7));
};


/**
 * @param {!Array<!proto.cloud_api.OrganizationSkillsetLink>} value
 * @return {!proto.cloud_api.Organization} returns this
*/
proto.cloud_api.Organization.prototype.setSkillsetLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.cloud_api.OrganizationSkillsetLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.OrganizationSkillsetLink}
 */
proto.cloud_api.Organization.prototype.addSkillsetLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.cloud_api.OrganizationSkillsetLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.clearSkillsetLinksList = function() {
  return this.setSkillsetLinksList([]);
};


/**
 * repeated FeatureSwitch.FeatureSwitchEnum switches = 8;
 * @return {!Array<!proto.cloud_api.FeatureSwitch.FeatureSwitchEnum>}
 */
proto.cloud_api.Organization.prototype.getSwitchesList = function() {
  return /** @type {!Array<!proto.cloud_api.FeatureSwitch.FeatureSwitchEnum>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.cloud_api.FeatureSwitch.FeatureSwitchEnum>} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setSwitchesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.cloud_api.FeatureSwitch.FeatureSwitchEnum} value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.addSwitches = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.clearSwitchesList = function() {
  return this.setSwitchesList([]);
};


/**
 * optional bool permission_granted_for_product_improvement = 12;
 * @return {boolean}
 */
proto.cloud_api.Organization.prototype.getPermissionGrantedForProductImprovement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setPermissionGrantedForProductImprovement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional DataUsageRights.DataUsageRightsEnum data_usage_rights = 13;
 * @return {!proto.cloud_api.DataUsageRights.DataUsageRightsEnum}
 */
proto.cloud_api.Organization.prototype.getDataUsageRights = function() {
  return /** @type {!proto.cloud_api.DataUsageRights.DataUsageRightsEnum} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.cloud_api.DataUsageRights.DataUsageRightsEnum} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setDataUsageRights = function(value) {
  return jspb.Message.setProto3EnumField(this, 13, value);
};


/**
 * optional OrgTier.OrgTierEnum org_tier = 14;
 * @return {!proto.cloud_api.OrgTier.OrgTierEnum}
 */
proto.cloud_api.Organization.prototype.getOrgTier = function() {
  return /** @type {!proto.cloud_api.OrgTier.OrgTierEnum} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.cloud_api.OrgTier.OrgTierEnum} value
 * @return {!proto.cloud_api.Organization} returns this
 */
proto.cloud_api.Organization.prototype.setOrgTier = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationGroupLink.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationGroupLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationGroupLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationGroupLink.toObject = function(includeInstance, msg) {
  var f, obj = {
groupName: jspb.Message.getFieldWithDefault(msg, 1, ""),
organizationPermission: jspb.Message.getFieldWithDefault(msg, 2, 0),
released: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationGroupLink}
 */
proto.cloud_api.OrganizationGroupLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationGroupLink;
  return proto.cloud_api.OrganizationGroupLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationGroupLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationGroupLink}
 */
proto.cloud_api.OrganizationGroupLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupName(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setOrganizationPermission(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReleased(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationGroupLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationGroupLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationGroupLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationGroupLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getReleased();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string group_name = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationGroupLink.prototype.getGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationGroupLink} returns this
 */
proto.cloud_api.OrganizationGroupLink.prototype.setGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OrgPermission.PermEnum organization_permission = 2;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.OrganizationGroupLink.prototype.getOrganizationPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.OrganizationGroupLink} returns this
 */
proto.cloud_api.OrganizationGroupLink.prototype.setOrganizationPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool released = 3;
 * @return {boolean}
 */
proto.cloud_api.OrganizationGroupLink.prototype.getReleased = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.OrganizationGroupLink} returns this
 */
proto.cloud_api.OrganizationGroupLink.prototype.setReleased = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.Organizations.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.Organizations.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.Organizations.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.Organizations} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Organizations.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationsList: jspb.Message.toObjectList(msg.getOrganizationsList(),
    proto.cloud_api.Organization.toObject, includeInstance),
pagination: (f = msg.getPagination()) && pbtypes_tools_cloud_api_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.Organizations}
 */
proto.cloud_api.Organizations.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.Organizations;
  return proto.cloud_api.Organizations.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.Organizations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.Organizations}
 */
proto.cloud_api.Organizations.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Organization;
      reader.readMessage(value,proto.cloud_api.Organization.deserializeBinaryFromReader);
      msg.addOrganizations(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_pagination_pb.Pagination;
      reader.readMessage(value,pbtypes_tools_cloud_api_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.Organizations.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.Organizations.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.Organizations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.Organizations.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.Organization.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      pbtypes_tools_cloud_api_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Organization organizations = 1;
 * @return {!Array<!proto.cloud_api.Organization>}
 */
proto.cloud_api.Organizations.prototype.getOrganizationsList = function() {
  return /** @type{!Array<!proto.cloud_api.Organization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.Organization, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Organization>} value
 * @return {!proto.cloud_api.Organizations} returns this
*/
proto.cloud_api.Organizations.prototype.setOrganizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Organization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Organization}
 */
proto.cloud_api.Organizations.prototype.addOrganizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Organization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.Organizations} returns this
 */
proto.cloud_api.Organizations.prototype.clearOrganizationsList = function() {
  return this.setOrganizationsList([]);
};


/**
 * optional Pagination pagination = 2;
 * @return {?proto.cloud_api.Pagination}
 */
proto.cloud_api.Organizations.prototype.getPagination = function() {
  return /** @type{?proto.cloud_api.Pagination} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_pagination_pb.Pagination, 2));
};


/**
 * @param {?proto.cloud_api.Pagination|undefined} value
 * @return {!proto.cloud_api.Organizations} returns this
*/
proto.cloud_api.Organizations.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.Organizations} returns this
 */
proto.cloud_api.Organizations.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.Organizations.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.UpdateOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.UpdateOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
organization: (f = msg.getOrganization()) && proto.cloud_api.Organization.toObject(includeInstance, f),
updateMask: (f = msg.getUpdateMask()) && google_protobuf_field_mask_pb.FieldMask.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.UpdateOrganizationRequest}
 */
proto.cloud_api.UpdateOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.UpdateOrganizationRequest;
  return proto.cloud_api.UpdateOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.UpdateOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.UpdateOrganizationRequest}
 */
proto.cloud_api.UpdateOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.Organization;
      reader.readMessage(value,proto.cloud_api.Organization.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_field_mask_pb.FieldMask;
      reader.readMessage(value,google_protobuf_field_mask_pb.FieldMask.deserializeBinaryFromReader);
      msg.setUpdateMask(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.UpdateOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.UpdateOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.UpdateOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cloud_api.Organization.serializeBinaryToWriter
    );
  }
  f = message.getUpdateMask();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_field_mask_pb.FieldMask.serializeBinaryToWriter
    );
  }
};


/**
 * optional Organization organization = 1;
 * @return {?proto.cloud_api.Organization}
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.getOrganization = function() {
  return /** @type{?proto.cloud_api.Organization} */ (
    jspb.Message.getWrapperField(this, proto.cloud_api.Organization, 1));
};


/**
 * @param {?proto.cloud_api.Organization|undefined} value
 * @return {!proto.cloud_api.UpdateOrganizationRequest} returns this
*/
proto.cloud_api.UpdateOrganizationRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateOrganizationRequest} returns this
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.FieldMask update_mask = 2;
 * @return {?proto.google.protobuf.FieldMask}
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.getUpdateMask = function() {
  return /** @type{?proto.google.protobuf.FieldMask} */ (
    jspb.Message.getWrapperField(this, google_protobuf_field_mask_pb.FieldMask, 2));
};


/**
 * @param {?proto.google.protobuf.FieldMask|undefined} value
 * @return {!proto.cloud_api.UpdateOrganizationRequest} returns this
*/
proto.cloud_api.UpdateOrganizationRequest.prototype.setUpdateMask = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.UpdateOrganizationRequest} returns this
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.clearUpdateMask = function() {
  return this.setUpdateMask(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.UpdateOrganizationRequest.prototype.hasUpdateMask = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.InviteUserToOrganizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.InviteUserToOrganizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.InviteUserToOrganizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.InviteUserToOrganizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
organizationPermission: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.InviteUserToOrganizationRequest}
 */
proto.cloud_api.InviteUserToOrganizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.InviteUserToOrganizationRequest;
  return proto.cloud_api.InviteUserToOrganizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.InviteUserToOrganizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.InviteUserToOrganizationRequest}
 */
proto.cloud_api.InviteUserToOrganizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setOrganizationPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.InviteUserToOrganizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.InviteUserToOrganizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.InviteUserToOrganizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.InviteUserToOrganizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganizationPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional OrgPermission.PermEnum organization_permission = 1;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.InviteUserToOrganizationRequest.prototype.getOrganizationPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.InviteUserToOrganizationRequest} returns this
 */
proto.cloud_api.InviteUserToOrganizationRequest.prototype.setOrganizationPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.InviteUserToOrganizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.InviteUserToOrganizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.InviteUserToOrganizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.InviteUserToOrganizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
invited: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
updated: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.InviteUserToOrganizationResponse}
 */
proto.cloud_api.InviteUserToOrganizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.InviteUserToOrganizationResponse;
  return proto.cloud_api.InviteUserToOrganizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.InviteUserToOrganizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.InviteUserToOrganizationResponse}
 */
proto.cloud_api.InviteUserToOrganizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvited(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUpdated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.InviteUserToOrganizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.InviteUserToOrganizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.InviteUserToOrganizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.InviteUserToOrganizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvited();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getUpdated();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool invited = 1;
 * @return {boolean}
 */
proto.cloud_api.InviteUserToOrganizationResponse.prototype.getInvited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.InviteUserToOrganizationResponse} returns this
 */
proto.cloud_api.InviteUserToOrganizationResponse.prototype.setInvited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool updated = 2;
 * @return {boolean}
 */
proto.cloud_api.InviteUserToOrganizationResponse.prototype.getUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.InviteUserToOrganizationResponse} returns this
 */
proto.cloud_api.InviteUserToOrganizationResponse.prototype.setUpdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationSkillsetLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationSkillsetLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationSkillsetLink.toObject = function(includeInstance, msg) {
  var f, obj = {
skillsetId: jspb.Message.getFieldWithDefault(msg, 1, ""),
skillsetName: jspb.Message.getFieldWithDefault(msg, 2, ""),
organizationPermission: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationSkillsetLink}
 */
proto.cloud_api.OrganizationSkillsetLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationSkillsetLink;
  return proto.cloud_api.OrganizationSkillsetLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationSkillsetLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationSkillsetLink}
 */
proto.cloud_api.OrganizationSkillsetLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSkillsetName(value);
      break;
    case 3:
      var value = /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (reader.readEnum());
      msg.setOrganizationPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationSkillsetLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationSkillsetLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationSkillsetLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkillsetId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSkillsetName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganizationPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string skillset_id = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.getSkillsetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationSkillsetLink} returns this
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.setSkillsetId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string skillset_name = 2;
 * @return {string}
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.getSkillsetName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationSkillsetLink} returns this
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.setSkillsetName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrgPermission.PermEnum organization_permission = 3;
 * @return {!proto.cloud_api.OrgPermission.PermEnum}
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.getOrganizationPermission = function() {
  return /** @type {!proto.cloud_api.OrgPermission.PermEnum} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.cloud_api.OrgPermission.PermEnum} value
 * @return {!proto.cloud_api.OrganizationSkillsetLink} returns this
 */
proto.cloud_api.OrganizationSkillsetLink.prototype.setOrganizationPermission = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.OrganizationStats.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationStats.toObject = function(includeInstance, msg) {
  var f, obj = {
totalsList: jspb.Message.toObjectList(msg.getTotalsList(),
    pbtypes_tools_cloud_api_stats_pb.Statistic.toObject, includeInstance),
averagesList: jspb.Message.toObjectList(msg.getAveragesList(),
    pbtypes_tools_cloud_api_stats_pb.Statistic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationStats}
 */
proto.cloud_api.OrganizationStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationStats;
  return proto.cloud_api.OrganizationStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationStats}
 */
proto.cloud_api.OrganizationStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_api_stats_pb.Statistic;
      reader.readMessage(value,pbtypes_tools_cloud_api_stats_pb.Statistic.deserializeBinaryFromReader);
      msg.addTotals(value);
      break;
    case 2:
      var value = new pbtypes_tools_cloud_api_stats_pb.Statistic;
      reader.readMessage(value,pbtypes_tools_cloud_api_stats_pb.Statistic.deserializeBinaryFromReader);
      msg.addAverages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      pbtypes_tools_cloud_api_stats_pb.Statistic.serializeBinaryToWriter
    );
  }
  f = message.getAveragesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      pbtypes_tools_cloud_api_stats_pb.Statistic.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Statistic totals = 1;
 * @return {!Array<!proto.cloud_api.Statistic>}
 */
proto.cloud_api.OrganizationStats.prototype.getTotalsList = function() {
  return /** @type{!Array<!proto.cloud_api.Statistic>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_stats_pb.Statistic, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Statistic>} value
 * @return {!proto.cloud_api.OrganizationStats} returns this
*/
proto.cloud_api.OrganizationStats.prototype.setTotalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Statistic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Statistic}
 */
proto.cloud_api.OrganizationStats.prototype.addTotals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Statistic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.OrganizationStats} returns this
 */
proto.cloud_api.OrganizationStats.prototype.clearTotalsList = function() {
  return this.setTotalsList([]);
};


/**
 * repeated Statistic averages = 2;
 * @return {!Array<!proto.cloud_api.Statistic>}
 */
proto.cloud_api.OrganizationStats.prototype.getAveragesList = function() {
  return /** @type{!Array<!proto.cloud_api.Statistic>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_stats_pb.Statistic, 2));
};


/**
 * @param {!Array<!proto.cloud_api.Statistic>} value
 * @return {!proto.cloud_api.OrganizationStats} returns this
*/
proto.cloud_api.OrganizationStats.prototype.setAveragesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cloud_api.Statistic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Statistic}
 */
proto.cloud_api.OrganizationStats.prototype.addAverages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cloud_api.Statistic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.OrganizationStats} returns this
 */
proto.cloud_api.OrganizationStats.prototype.clearAveragesList = function() {
  return this.setAveragesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.OrganizationPilotStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationPilotStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationPilotStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationPilotStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationPilotStats.toObject = function(includeInstance, msg) {
  var f, obj = {
statsList: jspb.Message.toObjectList(msg.getStatsList(),
    pbtypes_tools_cloud_api_stats_pb.Statistic.toObject, includeInstance),
idType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationPilotStats}
 */
proto.cloud_api.OrganizationPilotStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationPilotStats;
  return proto.cloud_api.OrganizationPilotStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationPilotStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationPilotStats}
 */
proto.cloud_api.OrganizationPilotStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_api_stats_pb.Statistic;
      reader.readMessage(value,pbtypes_tools_cloud_api_stats_pb.Statistic.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    case 2:
      var value = /** @type {!proto.cloud_api.OrganizationPilotStats.IdTypeEnum} */ (reader.readEnum());
      msg.setIdType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationPilotStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationPilotStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationPilotStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationPilotStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      pbtypes_tools_cloud_api_stats_pb.Statistic.serializeBinaryToWriter
    );
  }
  f = message.getIdType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.OrganizationPilotStats.IdTypeEnum = {
  NO_BUCKET: 0,
  HOUR: 1,
  DAY: 2,
  MONTH: 3
};

/**
 * repeated Statistic stats = 1;
 * @return {!Array<!proto.cloud_api.Statistic>}
 */
proto.cloud_api.OrganizationPilotStats.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.cloud_api.Statistic>} */ (
    jspb.Message.getRepeatedWrapperField(this, pbtypes_tools_cloud_api_stats_pb.Statistic, 1));
};


/**
 * @param {!Array<!proto.cloud_api.Statistic>} value
 * @return {!proto.cloud_api.OrganizationPilotStats} returns this
*/
proto.cloud_api.OrganizationPilotStats.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.Statistic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.Statistic}
 */
proto.cloud_api.OrganizationPilotStats.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.Statistic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.OrganizationPilotStats} returns this
 */
proto.cloud_api.OrganizationPilotStats.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};


/**
 * optional IdTypeEnum id_type = 2;
 * @return {!proto.cloud_api.OrganizationPilotStats.IdTypeEnum}
 */
proto.cloud_api.OrganizationPilotStats.prototype.getIdType = function() {
  return /** @type {!proto.cloud_api.OrganizationPilotStats.IdTypeEnum} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cloud_api.OrganizationPilotStats.IdTypeEnum} value
 * @return {!proto.cloud_api.OrganizationPilotStats} returns this
 */
proto.cloud_api.OrganizationPilotStats.prototype.setIdType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.StorageStat.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.StorageStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.StorageStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.StorageStat.toObject = function(includeInstance, msg) {
  var f, obj = {
forDuration: jspb.Message.getFieldWithDefault(msg, 1, ""),
size: jspb.Message.getFieldWithDefault(msg, 2, 0),
kind: jspb.Message.getFieldWithDefault(msg, 3, ""),
durationType: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.StorageStat}
 */
proto.cloud_api.StorageStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.StorageStat;
  return proto.cloud_api.StorageStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.StorageStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.StorageStat}
 */
proto.cloud_api.StorageStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setForDuration(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKind(value);
      break;
    case 4:
      var value = /** @type {!proto.cloud_api.StorageStat.DurationType} */ (reader.readEnum());
      msg.setDurationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.StorageStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.StorageStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.StorageStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.StorageStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForDuration();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getKind();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurationType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cloud_api.StorageStat.DurationType = {
  NO_DURATION: 0,
  HOUR: 1,
  DAY: 2,
  MONTH: 3,
  ALL_TIME: 4
};

/**
 * optional string for_duration = 1;
 * @return {string}
 */
proto.cloud_api.StorageStat.prototype.getForDuration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.StorageStat} returns this
 */
proto.cloud_api.StorageStat.prototype.setForDuration = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 size = 2;
 * @return {number}
 */
proto.cloud_api.StorageStat.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.StorageStat} returns this
 */
proto.cloud_api.StorageStat.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string kind = 3;
 * @return {string}
 */
proto.cloud_api.StorageStat.prototype.getKind = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.StorageStat} returns this
 */
proto.cloud_api.StorageStat.prototype.setKind = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DurationType duration_type = 4;
 * @return {!proto.cloud_api.StorageStat.DurationType}
 */
proto.cloud_api.StorageStat.prototype.getDurationType = function() {
  return /** @type {!proto.cloud_api.StorageStat.DurationType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.cloud_api.StorageStat.DurationType} value
 * @return {!proto.cloud_api.StorageStat} returns this
 */
proto.cloud_api.StorageStat.prototype.setDurationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cloud_api.StorageStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.StorageStats.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.StorageStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.StorageStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.StorageStats.toObject = function(includeInstance, msg) {
  var f, obj = {
statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.cloud_api.StorageStat.toObject, includeInstance),
totalSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.StorageStats}
 */
proto.cloud_api.StorageStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.StorageStats;
  return proto.cloud_api.StorageStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.StorageStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.StorageStats}
 */
proto.cloud_api.StorageStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cloud_api.StorageStat;
      reader.readMessage(value,proto.cloud_api.StorageStat.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTotalSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.StorageStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.StorageStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.StorageStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.StorageStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cloud_api.StorageStat.serializeBinaryToWriter
    );
  }
  f = message.getTotalSize();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * repeated StorageStat stats = 1;
 * @return {!Array<!proto.cloud_api.StorageStat>}
 */
proto.cloud_api.StorageStats.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.cloud_api.StorageStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cloud_api.StorageStat, 1));
};


/**
 * @param {!Array<!proto.cloud_api.StorageStat>} value
 * @return {!proto.cloud_api.StorageStats} returns this
*/
proto.cloud_api.StorageStats.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cloud_api.StorageStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cloud_api.StorageStat}
 */
proto.cloud_api.StorageStats.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cloud_api.StorageStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cloud_api.StorageStats} returns this
 */
proto.cloud_api.StorageStats.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};


/**
 * optional uint64 total_size = 4;
 * @return {number}
 */
proto.cloud_api.StorageStats.prototype.getTotalSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cloud_api.StorageStats} returns this
 */
proto.cloud_api.StorageStats.prototype.setTotalSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationLogoUploadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationLogoUploadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationLogoUploadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
mimetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
image: msg.getImage_asB64(),
md5sum: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationLogoUploadRequest}
 */
proto.cloud_api.OrganizationLogoUploadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationLogoUploadRequest;
  return proto.cloud_api.OrganizationLogoUploadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationLogoUploadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationLogoUploadRequest}
 */
proto.cloud_api.OrganizationLogoUploadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMimetype(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMd5sum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationLogoUploadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationLogoUploadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationLogoUploadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMimetype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getMd5sum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string filename = 1;
 * @return {string}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationLogoUploadRequest} returns this
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mimetype = 2;
 * @return {string}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.getMimetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationLogoUploadRequest} returns this
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.setMimetype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes image = 3;
 * @return {!(string|Uint8Array)}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes image = 3;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.cloud_api.OrganizationLogoUploadRequest} returns this
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional string md5sum = 4;
 * @return {string}
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.getMd5sum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.OrganizationLogoUploadRequest} returns this
 */
proto.cloud_api.OrganizationLogoUploadRequest.prototype.setMd5sum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.OrganizationSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.OrganizationSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.OrganizationSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
livestreamSettings: (f = msg.getLivestreamSettings()) && pbtypes_tools_cloud_api_livestream_settings_pb.LivestreamSettings.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.OrganizationSettings}
 */
proto.cloud_api.OrganizationSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.OrganizationSettings;
  return proto.cloud_api.OrganizationSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.OrganizationSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.OrganizationSettings}
 */
proto.cloud_api.OrganizationSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new pbtypes_tools_cloud_api_livestream_settings_pb.LivestreamSettings;
      reader.readMessage(value,pbtypes_tools_cloud_api_livestream_settings_pb.LivestreamSettings.deserializeBinaryFromReader);
      msg.setLivestreamSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.OrganizationSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.OrganizationSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.OrganizationSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.OrganizationSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLivestreamSettings();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      pbtypes_tools_cloud_api_livestream_settings_pb.LivestreamSettings.serializeBinaryToWriter
    );
  }
};


/**
 * optional LivestreamSettings livestream_settings = 1;
 * @return {?proto.cloud_api.LivestreamSettings}
 */
proto.cloud_api.OrganizationSettings.prototype.getLivestreamSettings = function() {
  return /** @type{?proto.cloud_api.LivestreamSettings} */ (
    jspb.Message.getWrapperField(this, pbtypes_tools_cloud_api_livestream_settings_pb.LivestreamSettings, 1));
};


/**
 * @param {?proto.cloud_api.LivestreamSettings|undefined} value
 * @return {!proto.cloud_api.OrganizationSettings} returns this
*/
proto.cloud_api.OrganizationSettings.prototype.setLivestreamSettings = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cloud_api.OrganizationSettings} returns this
 */
proto.cloud_api.OrganizationSettings.prototype.clearLivestreamSettings = function() {
  return this.setLivestreamSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cloud_api.OrganizationSettings.prototype.hasLivestreamSettings = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.ResourceGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.ResourceGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.ResourceGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ResourceGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
uuid: jspb.Message.getFieldWithDefault(msg, 1, ""),
organizationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
name: jspb.Message.getFieldWithDefault(msg, 3, ""),
isOrganizationDefault: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.ResourceGroup}
 */
proto.cloud_api.ResourceGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.ResourceGroup;
  return proto.cloud_api.ResourceGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.ResourceGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.ResourceGroup}
 */
proto.cloud_api.ResourceGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganizationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOrganizationDefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.ResourceGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.ResourceGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.ResourceGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.ResourceGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUuid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganizationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsOrganizationDefault();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.cloud_api.ResourceGroup.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ResourceGroup} returns this
 */
proto.cloud_api.ResourceGroup.prototype.setUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organization_id = 2;
 * @return {string}
 */
proto.cloud_api.ResourceGroup.prototype.getOrganizationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ResourceGroup} returns this
 */
proto.cloud_api.ResourceGroup.prototype.setOrganizationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cloud_api.ResourceGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cloud_api.ResourceGroup} returns this
 */
proto.cloud_api.ResourceGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_organization_default = 4;
 * @return {boolean}
 */
proto.cloud_api.ResourceGroup.prototype.getIsOrganizationDefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cloud_api.ResourceGroup} returns this
 */
proto.cloud_api.ResourceGroup.prototype.setIsOrganizationDefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


goog.object.extend(exports, proto.cloud_api);
